import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Estilos para React-PDF
const styles = StyleSheet.create({
    page: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "30px",
        backgroundColor: "white",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    table: {
        display: "table",
        width: "auto",
        margin: "5px 0",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        width: "100%",
        marginTop: 5
    },
    tableCellHeader: {
        backgroundColor: "#eee",
        fontWeight: "bold",
        paddingBottom: 5
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10,
    },
});

function PagosPdf({ sicobe }) {
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    return (
        <Document>
            <Page style={styles.page} size={"A4"}>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableCellHeader]}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Patente</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Poliza/Prop</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Inicio Vig</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Fin Vig</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Cant Pagos</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Cobertura</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Anu/Rehab</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Asegurado</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Sum. Aseg</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>F. Emision</Text>
                        </View>
                    </View>
                    {sicobe?.otrasDenunciasQnx?.porPatente.map((d, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.ados_patente}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.ados_poliza}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{formatDate(d.ados_fech_ini)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{formatDate(d.ados_fech_fin)}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.pagos.length}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.ados_cobert}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.anurehab[0]?.anu_tipo === 1 ? 'A' : (d.anurehab[0]?.anu_tipo === 2 ? 'R' : '')}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.ados_nom_aseg}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{d.ados_sum_aseg}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{formatDate(d.ados_fech_emi)}</Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={styles.pageNumber}><Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} /></View> : ''
            </Page>
        </Document>
    )
};


export default PagosPdf;
