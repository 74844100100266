import axios from "axios";
import { useCallback, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
export default function Header({ denuncia, asignadoMas1, asignadoMenos1, setSelectedOption }) {
    function navigateTo1(e) {
        e.preventDefault()
        navigate(`/${asignadoMenos1.id}`)
        window.location.reload()
    }
    const navigate = useNavigate()
    const link = { textDecoration: "none" };
    function navigateTo(e) {
        e.preventDefault()
        navigate(`/${asignadoMas1.id}`)
        window.location.reload()
    }
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [nro, setNro] = useState(null)
    const buscarNro = useCallback(async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get('https://apifotos.agsseguros.online/api/buscarNroInput', {params: { nro }});
            const data = response.data;
            navigate(`/${data.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [nro]);
    return (
        <div className="headerStro">
            <div className="d-flex align-items-center">
                {asignadoMenos1 ? <button onClick={(e) => navigateTo1(e)} className="btn btn-danger" style={link}>{asignadoMenos1.entryId}</button> : <button disabled className="btn btn-danger btn-opacity-50" style={link}>----</button>}
                {denuncia.length !== 0 ? <div className="insideArrowStro center me-2 ms-2">
                    {denuncia.nroStro !== null ? `STRO.${denuncia?.nroStro?.toString().substring(3)}` : `DEN.${denuncia?.entryId}`}
                </div> : <div className="insideArrowStro center"></div>}
                {asignadoMas1 ? <button onClick={(e) => navigateTo(e)} className="btn btn-primary" style={link}>{asignadoMas1.entryId}</button> : null}
                <div className="center ms-5">
                    <input className="form-control form-control-sm w-75" type="number" placeholder="Buscar Stro" value={nro} onChange={(e) => setNro(e.target.value)}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            buscarNro(e)
                        }
                    }}></input>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <div className="btnCargaArrowStro center text-align-center text-white ps-1 me-2">
                    <i class="bi bi-grid iconGridArrow"></i>
                    <p className="textCargaBtnArrowStro m-0 p-0 ms-1">CARGA</p>
                </div>
                <Link to={`/`} style={link} className="btnCargaArrowStro center text-align-center text-white ms-2" >
                    <i class="bi bi-bar-chart-fill iconGridArrow"></i>
                    <p className="m-0 textCargaBtnArrowStro m-0 p-0 ms-1">TABLA</p>
                </Link>
            </div>
        </div>
    )
}