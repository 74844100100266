import { useCallback, useState } from 'react';
import './choques.css'
import axios from 'axios'
import { useCookies } from 'react-cookie';
import MensajesCronologiaCarga from "../../components/carga/MensajesCronologiaMensajes";
import Select from "react-select";
export default function ModuloDerEstadoChoquesTransitorioChat({ mensajes, denuncia }) {
    const [cookies] = useCookies(['user']);
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    const [mensaje, setMensaje] = useState({ obs: null, motivo: null })
    const motivoMensajeInput = [{ label: "AMP", value: "AMP" }, { label: "OTROS", value: "OTROS" }, {label: 'RR', value: 'RR'}, {label: 'CTZ', value: 'CTZ'}, {label: 'ASIG', value: 'ASIG'}, {label: 'RCIA', value: 'RCIA'}, {label: 'IN/REC', value: 'IN/REC'}, {label: 'TER', value: 'TER'}, {label: 'CER', value: 'CER'}, {label: 'ARCH', value: 'ARCH'}, {label: 'AUD', value: 'AUD'}, {label: 'DDA', value: 'DDA'}, {label: 'INF', value: 'INF'}]
    const comentarioUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
            {
                id: denuncia.id,
                desc: '-',
                obs: mensaje.obs,
                jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                motivo: mensaje.motivo,
                autor: cookies.user.usuario,
                fecha: formattedDate,
                hora: currentTime
            }
        ).then((response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [mensaje, denuncia, cookies, currentTime, formattedDate]);
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white'
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? '#42C3BA'
                : isSelected
                    ? '#42C3BA'
                    : undefined,
            border: isFocused
                ? '#42C3BA 1px solid'
                : isSelected
                    ? '#42C3BA 1px solid'
                    : undefined,
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    }
    return (
        <section className='moduloEstadoChoquesTransitorioChat pt-2 pe-3 ps-3'>
            {mensajes.map((m) => (
                <MensajesCronologiaCarga mensaje={m} />
            ))}
            <div className="d-flex escribirMensajeTrnsitorioChoque w-100 mt-3">
                <textarea className="mensajeCronoliaStro ps-3 pt-1" value={mensaje.obs}
                    onChange={(e) =>
                        setMensaje((prevState) => ({
                            ...prevState,
                            obs: e.target.value,
                        }))} />
                <div className="sendContainer">
                    <div className="sendMessageBtnContainer center">
                        <button className="btnNO text-white" onClick={(e) => comentarioUpdate(e)} disabled={mensaje.motivo === null ? true : false}>
                            <i class="bi bi-send"></i>
                        </button>
                    </div>
                    <div className="sendMessageUserContainer mt-2">
                        <Select
                            styles={colourStyles}
                            options={motivoMensajeInput} className='w-100'
                            placeholder=''
                            onChange={(newValue) => setMensaje((prevState) => ({
                                ...prevState,
                                motivo: newValue.value,
                            }))}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
