import { useCallback, useEffect, useState, useRef } from 'react';
import './carga.css'
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
export default function ActualizarDenuncia() {
    const id = useParams().id;
    const [cookies] = useCookies(["user"], ['asignado']);
    const [denuncia, setDenuncia] = useState()
    const [denunciaUpdate, setDenunciaUpdate] = useState();
    const [cargando, setCargando] = useState(false)
    const [link, setLink] = useState()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://apifotos.agsseguros.online/api/verTodoEditar/${id}`);
                setDenuncia(resDenuncia.data)
                setDenunciaUpdate(resDenuncia.data);
                setLink(resDenuncia.data.link)
                setCargando(true)
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    const expedienteDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        const changedFields = [];
        for (const key in denunciaUpdate) {
            if (denunciaUpdate.hasOwnProperty(key) && denunciaUpdate[key] !== denuncia[key]) {
                changedFields.push(key);
            }
        }
        await axios.post(
            `https://apifotos.agsseguros.online/api/actualizarStro`,
            {
                id: denuncia.id,
                pza: denunciaUpdate.pza,
                pas: denunciaUpdate.pas,
                email: denunciaUpdate.email,
                codp: denunciaUpdate.codp,
                tipoStro: denunciaUpdate.tipoStro,
                fechaDenuncia: denunciaUpdate.fechaDenuncia,
                fechaStro: denunciaUpdate.fechaStro,
                horaStro: denunciaUpdate.horaStro,
                direccionStroCalle: denunciaUpdate.direccionStroCalle,
                direccionStroNumero: denunciaUpdate.direccionStroNumero,
                direccionStroProvincia: denunciaUpdate.direccionStroProvincia,
                direccionStroLocalidad: denunciaUpdate.direccionStroLocalidad,
                direccionStroCP: denunciaUpdate.direccionStroCP,
                direccionStroPais: denunciaUpdate.direccionStroPais,
                interseccionStro: denunciaUpdate.interseccionStro,
                interseccionStro2: denunciaUpdate.interseccionStro2,
                detallesStro: denunciaUpdate.detallesStro,
                si: denunciaUpdate.si,
                nombreConductorAseg: denunciaUpdate.nombreConductorAseg,
                dniConductorAseg: denunciaUpdate.dniConductorAseg,
                telefonoConductorAseg: denunciaUpdate.telefonoConductorAseg,
                direccionConductorAsegDomicilio: denunciaUpdate.direccionConductorAsegDomicilio,
                direccionConductorAsegProvincia: denunciaUpdate.direccionConductorAsegProvincia,
                direccionConductorAsegLocalidad: denunciaUpdate.direccionConductorAsegLocalidad,
                direccionConductorAsegCP: denunciaUpdate.direccionConductorAsegCP,
                direccionConductorAsegPais: denunciaUpdate.direccionConductorAsegPais,
                registroConductorAseg: denunciaUpdate.registroConductorAseg,
                vencimientoConductorAseg: denunciaUpdate.vencimientoConductorAseg,
                relacionConAseg: denunciaUpdate.relacionConAseg,
                nombreAseg: denunciaUpdate.nombreAseg,
                dniAseg: denunciaUpdate.dniAseg,
                telefonoAseg: denunciaUpdate.telefonoAseg,
                direccionAsegDomicilio: denunciaUpdate.direccionAsegDomicilio,
                direccionAsegProvincia: denunciaUpdate.direccionAsegProvincia,
                direccionAsegLocalidad: denunciaUpdate.direccionAsegLocalidad,
                direccionAsegCP: denunciaUpdate.direccionAsegCP,
                direccionAsegPais: denunciaUpdate.direccionAsegPais,
                marcaAseg: denunciaUpdate.marcaAseg,
                tipoAseg: denunciaUpdate.tipoAseg,
                dom: denunciaUpdate.dom,
                anioAseg: denunciaUpdate.anioAseg,
                motorAseg: denunciaUpdate.motorAseg,
                chasisAseg: denunciaUpdate.chasisAseg,
                detallesDaniosAseg: denunciaUpdate.detallesDaniosAseg,
                propietarioTercero1: denunciaUpdate.propietarioTercero1,
                dniTercero1: denunciaUpdate.dniTercero1,
                telefonoTercero1: denunciaUpdate.telefonoTercero1,
                direccionTercero1Domicilio: denunciaUpdate.direccionTercero1Domicilio,
                direccionTercero1Provincia: denunciaUpdate.direccionTercero1Provincia,
                direccionTercero1Localidad: denunciaUpdate.direccionTercero1Localidad,
                direccionTercero1CP: denunciaUpdate.direccionTercero1CP,
                direccionTercero1Pais: denunciaUpdate.direccionTercero1Pais,
                marcaTercero1: denunciaUpdate.marcaTercero1,
                modeloTercero1: denunciaUpdate.modeloTercero1,
                tipoTercero1: denunciaUpdate.tipoTercero1,
                dominioTercero1: denunciaUpdate.dominioTercero1,
                anioTercero1: denunciaUpdate.anioTercero1,
                motorTercero1: denunciaUpdate.motorTercero1,
                chasisTercero1: denunciaUpdate.chasisTercero1,
                ciaTercero1: denunciaUpdate.ciaTercero1,
                polizaTercero1: denunciaUpdate.polizaTercero1,
                danioTercero1: denunciaUpdate.danioTercero1,
                conductorTercero1: denunciaUpdate.conductorTercero1,
                dniConductorTercero1: denunciaUpdate.dniConductorTercero1,
                telefonoConductorTercero1: denunciaUpdate.telefonoConductorTercero1,
                direccionConductorTercero1Domicilio: denunciaUpdate.direccionConductorTercero1Domicilio,
                direccionConductorTercero1Localidad: denunciaUpdate.direccionConductorTercero1Localidad,
                direccionConductorTercero1Provincia: denunciaUpdate.direccionTercero1Provincia,
                direccionConductorTercero1CP: denunciaUpdate.direccionConductorTercero1CP,
                direccionConductorTercero1Pais: denunciaUpdate.direccionConductorTercero1Pais,
                ciaConductorTercero1: denunciaUpdate.ciaConductorTercero1,
                polizaConductorTercero1: denunciaUpdate.polizaConductorTercero1,
                estadoCivilConductorTercero1: denunciaUpdate.estadoCivilConductorTercero1,
                fechaNacConductorTercero1: denunciaUpdate.fechaNacConductorTercero1,
                si1: denunciaUpdate.si1,
                no: denunciaUpdate.no,
                registroTercero1: denunciaUpdate.registroTercero1,
                vencimientoTercero1: denunciaUpdate.vencimientoTercero1,
                propietarioTercero2: denunciaUpdate.propietarioTercero2,
                dniTercero2: denunciaUpdate.dniTercero2,
                telefonoTercero2: denunciaUpdate.telefonoTercero2,
                direccionTercero2Domicilio: denunciaUpdate.direccionConductorTercero2Domicilio,
                direccionTercero2Provincia: denunciaUpdate.direccionConductorTercero2Provincia,
                direccionTercero2Localidad: denunciaUpdate.direccionTercero2Localidad,
                direccionTercero2CP: denunciaUpdate.direccionTercero2CP,
                direccionTercero2Pais: denunciaUpdate.direccionTercero2Pais,
                marcaTercero2: denunciaUpdate.marcaTercero2,
                modeloTercero2: denunciaUpdate.modeloTercero2,
                tipoTercero2: denunciaUpdate.tipoTercero2,
                dominioTercero2: denunciaUpdate.dominioTercero2,
                anioTercero2: denunciaUpdate.anioTercero2,
                motorTercero2: denunciaUpdate.motorTercero2,
                chasisTercero2: denunciaUpdate.chasisTercero2,
                detallesDanioTercero2: denunciaUpdate.detallesDanioTercero2,
                conductorTercero2: denunciaUpdate.conductorTercero2,
                dniConductorTercero2: denunciaUpdate.dniConductorTercero2,
                telefonoConductorTercero2: denunciaUpdate.telefonoConductorTercero2,
                direccionConductorTercero2Domicilio: denunciaUpdate.direccionConductorTercero2Domicilio,
                direccionConductorTercero2Provincia: denunciaUpdate.direccionConductorTercero2Provincia,
                direccionConductorTercero2Localidad: denunciaUpdate.direccionConductorTercero2Localidad,
                direccionConductorTercero2CP: denunciaUpdate.direccionConductorTercero2CP,
                direccionConductorTercero2Pais: denunciaUpdate.direccionConductorTercero2Pais,
                estadoCivilConductorTercero2: denunciaUpdate.estadoCivilConductorTercero2,
                fechaNacimientoConductorTercero2: denunciaUpdate.fechaNacimientoConductorTercero2,
                conductorHabitual: denunciaUpdate.conductorHabitual,
                registroTercero2: denunciaUpdate.registroTercero2,
                vencimientoTercero2: denunciaUpdate.vencimientoTercero2,
                propietarioMat: denunciaUpdate.propietarioMat,
                dniMat: denunciaUpdate.dniMat,
                telefonoMat: denunciaUpdate.telefonoMat,
                direccionMatDomicilio: denunciaUpdate.direccionMatDomicilio,
                direccionMatProvincia: denunciaUpdate.direccionMatProvincia,
                direccionMatLocalidad: denunciaUpdate.direccionMatLocalidad,
                direccionMatCP: denunciaUpdate.direccionMatCP,
                direccionMatPais: denunciaUpdate.direccionMatPais,
                detalleDaniosMat: denunciaUpdate.detalleDaniosMat,
                nombreLesTercero: denunciaUpdate.nombreLesTercero,
                dniLesTercero: denunciaUpdate.dniLesTercero,
                telefonoLesTercero: denunciaUpdate.telefonoLesTercero,
                direccionLesTerceroDomicilio: denunciaUpdate.direccionLesTerceroDomicilio,
                direccionLesTerceroProvincia: denunciaUpdate.direccionLesTerceroProvincia,
                direccionLesTerceroLocalidad: denunciaUpdate.direccionLesTerceroLocalidad,
                direccionLesTerceroCP: denunciaUpdate.direccionLesTerceroCP,
                direccionLesTerceroPais: denunciaUpdate.direccionLesTerceroPais,
                estadoCivilTercero: denunciaUpdate.estadoCivilTercero,
                fechaNacimientoLesTercero: denunciaUpdate.fechaNacimientoLesTercero,
                conductorOtroVehiculo: denunciaUpdate.conductorOtroVehiculo,
                pasajeroVehiculoAsegurado: denunciaUpdate.pasajeroVehiculoAsegurado,
                pasajeroOtroVehiculo: denunciaUpdate.pasajeroOtroVehiculo,
                peaton: denunciaUpdate.peaton,
                tipoLesion: denunciaUpdate.tipoLesion,
                centroAsistencial: denunciaUpdate.centroAsistencial
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(`https://apifotos.agsseguros.online/api/denuncia/linkSharepoint`,
                    {
                        id: denuncia.id,
                        link: link,
                    })
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`, {
                    id: denuncia.id,
                    desc: `El usuario ha actualizado los siguientes campos: ${changedFields.join(', ')}`,
                    obs: '',
                    jerarquia: cookies.user.depto === "cargas" ? "CRG" : "DEN",
                    motivo: 'STRO',
                    autor: cookies.user.usuario,
                    fecha: formattedDate,
                    hora: currentTime
                }).then((
                    window.location.reload()
                ))
            }
        })
    }, [denunciaUpdate, denuncia, currentTime, formattedDate]);
    return (
        <>
            {cargando ? <form>
                <div className="row">
                    <div className="col">
                        <label class="mb-1">Pza</label>
                        <input type="text" class="form-control" value={denunciaUpdate.pza} placeholder={denuncia.pza}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    pza: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pas</label>
                        <input type="number" class="form-control" value={denunciaUpdate.pas} placeholder={denuncia.pas}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    pas: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pas-Email</label>
                        <input type="text" class="form-control" value={denunciaUpdate.email} placeholder={denuncia.email}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    email: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Codigo Productor</label>
                        <input type="number" class="form-control" value={denunciaUpdate.codp} placeholder={denuncia.codp}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    codp: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Tipo Stro</label>
                        <input type="text" class="form-control" value={denunciaUpdate.tipoStro} placeholder={denuncia.tipoStro} 
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    tipoStro: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Link Sharepoint</label>
                        <input type="text" class="form-control" value={link} placeholder={link}
                            onChange={(e) => setLink(e.target.value)} />
                    </div>
                </div>
                <h4 className='mt-5'>1-FECHA DEL SINIESTRO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">FECHA DENUNCIA</label>
                        <input type="date" class="form-control" value={denunciaUpdate.fechaDenuncia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    fechaDenuncia: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">FECHA OCURRENCIA</label>
                        <input type="date" class="form-control" value={denunciaUpdate.fechaStro}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    fechaStro: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">HORA DE OCURRENCIA</label>
                        <input type="time" class="form-control" value={denunciaUpdate.horaStro}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    horaStro: e.target.value,
                                }))} />
                    </div>
                </div>
                <h4 className='mt-5'>2-LUGAR DEL SINIESTRO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Calle</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroCalle}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroCalle: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Numero</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroNumero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroNumero: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroProvincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroProvincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroLocalidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroLocalidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroCP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroCP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionStroPais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionStroPais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">INTERSECCION DE/ ENTRE</label>
                        <input type="text" class="form-control" value={denunciaUpdate.interseccionStro}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    interseccionStro: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Y</label>
                        <input type="text" class="form-control" value={denunciaUpdate.interseccionStro2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    interseccionStro2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DETALLES DEL SINIESTRO</label>
                        <textarea
                            className="form-control"
                            value={denunciaUpdate.detallesStro}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    detallesStro: e.target.value,
                                }))}
                        />
                    </div>
                </div>
                <h4 className='mt-5'>3- DATOS DEL CONDUCTOR DEL VEHICULO ASEGURADO</h4>
                <hr className='mt-3 mb-3' />
                <div>
                    <label className='mb-1 d-block'>ES EL PROPIO ASEGURADO</label>
                    <input type="text" className='form-control' value={denunciaUpdate.si} />
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">NOMBRE Y APELLIDO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.nombreConductorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    nombreConductorAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniConductorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniConductorAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoConductorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoConductorAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorAsegDomicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorAsegDomicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">PROVINCIA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorAsegProvincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorAsegProvincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorAsegLocalidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorAsegLocalidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorAsegCP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorAsegCP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorAsegPais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorAsegPais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">REGISTRO N°</label>
                        <input type="text" class="form-control" value={denunciaUpdate.registroConductorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    registroConductorAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">VENCIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.vencimientoConductorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    vencimientoConductorAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">RELACION CON EL ASEGURADO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.relacionConAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    relacionConAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <h4 className='mt-5'>4-DATOS DEL ASEGURADO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">NOMBRE Y APELLIDO O RAZON SOCIAL</label>
                        <input type="text" class="form-control" value={denunciaUpdate.nombreAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    nombreAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionAsegDomicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionAsegDomicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">PROVINCIA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionAsegProvincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionAsegProvincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionAsegLocalidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionAsegLocalidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionAsegCP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionAsegCP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionAsegPais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionAsegPais: e.target.value,
                                }))} />
                    </div>
                </div>
                <h4 className='mt-5'>5- DATOS DEL VEHICULO ASEGURADO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">MARCA Y MODELO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.marcaAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    marcaAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO:</label>
                        <input type="text" class="form-control" value={denunciaUpdate.tipoAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    tipoAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DOMINIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dom}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dom: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">AÑO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.anioAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    anioAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">N° MOTOR</label>
                        <input type="text" class="form-control" value={denunciaUpdate.motorAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    motorAseg: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">N° CHASIS</label>
                        <input type="text" class="form-control" value={denunciaUpdate.chasisAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    chasisAseg: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DETALLES DEL SINIESTRO</label>
                        <textarea
                            className="form-control"
                            value={denunciaUpdate.detallesDaniosAseg}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    detallesDaniosAseg: e.target.value,
                                }))}
                        />
                    </div>
                </div>
                <h4 className='mt-5'>6-DETALLE DEL OTRO VEHICULO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">PROPIETARIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.propietarioTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    propietarioTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero1Domicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero1Domicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero1Provincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero1Provincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero1Localidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero1Localidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero1CP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero1CP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero1Pais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero1Pais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">MARCA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.marcaTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    marcaTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">MODELO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.modeloTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    modeloTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.tipoTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    tipoTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DOMINIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dominioTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dominioTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">AÑO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.anioTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    anioTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">NÚMERO MOTOR</label>
                        <input type="text" class="form-control" value={denunciaUpdate.motorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    motorTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">NÚMERO CHASIS</label>
                        <input type="text" class="form-control" value={denunciaUpdate.chasisTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    chasisTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">COMPAÑIA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.ciaTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    ciaTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">NÚMERO POLIZA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.polizaTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    polizaTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                        <textarea
                            className="form-control"
                            value={denunciaUpdate.danioTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    danioTercero1: e.target.value,
                                }))}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CONDUCTOR</label>
                        <input type="text" class="form-control" value={denunciaUpdate.conductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    conductorTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniConductorTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoConductorTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero1Domicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero1Domicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero1Localidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero1Localidad: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero1Provincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero1Provincia: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero1CP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero1CP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero1Pais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero1Pais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Compañia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.ciaConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    ciaConductorTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">N° POLIZA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.polizaConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    ciaConductorTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">ESTADO CIVIL</label>
                        <input type="text" class="form-control" value={denunciaUpdate.estadoCivilConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    estadoCivilConductorTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col-3">
                        <label class="mb-1">FECHA NACIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.fechaNacConductorTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    fechaNacConductorTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CONDUCTOR HABITUAL DEL VEHICULO?</label>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.si1} disabled />
                            <label className='d-block ms-2'>SI_</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.no} disabled />
                            <label className=' d-block ms-2'>NO_</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">REGISTRO N°</label>
                        <input type="text" class="form-control" value={denunciaUpdate.registroTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    registroTercero1: e.target.value,
                                }))} />
                    </div>
                    <div className="col-3">
                        <label class="mb-1">VENCIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.vencimientoTercero1}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    vencimientoTercero1: e.target.value,
                                }))} />
                    </div>
                </div>
                <h4 className='mt-5'>7- DETALLE DEL OTRO VEHICULO</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">PROPIETARIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.propietarioTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    propietarioTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero2Domicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero2Domicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero2Provincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero2Provincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero2Localidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero2Localidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero2CP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero2CP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionTercero2Pais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionTercero2Pais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">MARCA</label>
                        <input type="text" class="form-control" value={denunciaUpdate.marcaTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    marcaTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">MODELO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.modeloTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    modeloTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.tipoTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    tipoTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">DOMINIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dominioTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dominioTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">AÑO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.anioTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    anioTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">NÚMERO MOTOR</label>
                        <input type="text" class="form-control" value={denunciaUpdate.motorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    motorTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">NÚMERO CHASIS</label>
                        <input type="text" class="form-control" value={denunciaUpdate.chasisTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    chasisTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                        <textarea
                            className="form-control"
                            value={denunciaUpdate.detallesDanioTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    detallesDanioTercero2: e.target.value,
                                }))}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CONDUCTOR</label>
                        <input type="text" class="form-control" value={denunciaUpdate.conductorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    conductorTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniConductorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniConductorTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoConductorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoConductorTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero2Domicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero2Domicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero2Provincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero2Provincia: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero2Localidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero2Localidad: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero2CP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero2CP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionConductorTercero2Pais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionConductorTercero2Pais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">ESTADO CIVIL</label>
                        <input type="text" class="form-control" value={denunciaUpdate.estadoCivilConductorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    estadoCivilConductorTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col-3">
                        <label class="mb-1">FECHA NACIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.fechaNacimientoConductorTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    fechaNacimientoConductorTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CONDUCTOR HABITUAL DEL VEHICULO?</label>
                        <div className='d-flex'>
                            <input type="checkbox" disabled value={denunciaUpdate.conductorHabitual} />
                            <label className='d-block ms-2'>ConductorHabitual</label>
                        </div>
                        <p>Dejar destildado en caso contrario</p>
                    </div>
                    <div className="col">
                        <label class="mb-1">REGISTRO N°</label>
                        <input type="text" class="form-control" value={denunciaUpdate.registroTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    registroTercero2: e.target.value,
                                }))} />
                    </div>
                    <div className="col-3">
                        <label class="mb-1">VENCIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.vencimientoTercero2}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    vencimientoTercero2: e.target.value,
                                }))} />
                    </div>
                </div>
                <h4 className='mt-5'>8-DAÑOS MATERIALES A COSAS</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">PROPIETARIO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.propietarioMat}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    propietarioMat: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniMat}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniMat: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoMat}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoMat: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionMatDomicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionMatDomicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionMatProvincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionMatProvincia: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionMatLocalidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionMatLocalidad: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionMatCP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionMatCP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionMatPais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionMatPais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                        <textarea
                            className="form-control"
                            value={denunciaUpdate.detalleDaniosMat}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    detalleDaniosMat: e.target.value,
                                }))}
                        />
                    </div>
                </div>
                <h4 className='mt-5'>9-LESIONES A TERCEROS</h4>
                <hr className='mt-3 mb-3' />
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">NOMBRE Y APELLIDO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.nombreLesTercero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    nombreLesTercero: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO Y N° DOCUMENTO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.dniLesTercero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    dniLesTercero: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">TELEFONO</label>
                        <input type="text" class="form-control" value={denunciaUpdate.telefonoLesTercero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    telefonoLesTercero: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">Domicilio</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionLesTerceroDomicilio}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionLesTerceroDomicilio: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Provincia</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionLesTerceroProvincia}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionLesTerceroProvincia: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Localidad</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionLesTerceroLocalidad}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionLesTerceroLocalidad: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CP</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionLesTerceroCP}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionLesTerceroCP: e.target.value,
                                }))} />
                    </div>
                    <div className="col">
                        <label class="mb-1">Pais</label>
                        <input type="text" class="form-control" value={denunciaUpdate.direccionLesTerceroPais}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    direccionLesTerceroPais: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">ESTADO CIVIL</label>
                        <input type="text" class="form-control" value={denunciaUpdate.estadoCivilTercero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    estadoCivilTercero: e.target.value,
                                }))} />
                    </div>
                    <div className="col-3">
                        <label class="mb-1">FECHA NACIMIENTO</label>
                        <input type="date" class="form-control" value={denunciaUpdate.fechaNacimientoLesTercero}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    fechaNacimientoLesTercero: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">RELACION CON EL ASEGURADO</label>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.conductorOtroVehiculo} disabled />
                            <label className='d-block ms-2'>CONDUCTOR OTRO VEHICULO</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.pasajeroVehiculoAsegurado} disabled />
                            <label className='d-block ms-2'>PASAJERO DE VEHICULO ASEGURADO</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.pasajeroOtroVehiculo} disabled />
                            <label className='d-block ms-2'>PASAJERO OTRO VEHICULO</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.peaton} disabled />
                            <label className='d-block ms-2'>PEATON</label>
                        </div>
                    </div>
                    <div className="col">
                        <label class="mb-1">TIPO DE LESIONES</label>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.tipoLesion} disabled />
                            <label className='d-block ms-2'>LEVES</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.tipoLesion} disabled />
                            <label className='d-block ms-2'>GRAVE</label>
                        </div>
                        <div className='d-flex'>
                            <input type="checkbox" value={denunciaUpdate.tipoLesion} disabled />
                            <label className='d-block ms-2'>MORTAL</label>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <label class="mb-1">CENTRO ASISTENCIAL</label>
                        <input type="text" class="form-control" disabled value={denunciaUpdate.centroAsistencial} placeholder={denuncia.centroAsistencial}
                            onChange={(e) =>
                                setDenunciaUpdate((prevState) => ({
                                    ...prevState,
                                    centroAsistencial: e.target.value,
                                }))} />
                    </div>
                </div>
                <div className='center mt-5 mb-5' onClick={(e) => expedienteDataUpdate(e)}><button type="submit" className='btn btn-primary'>Actualizar</button></div>
            </form> : <div className="center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </>
    );
}