import './choques.css'
export default function ModuloDerEstadoChoquesTransitorio2({ stro, fechaStro, qnxInic, qnxFin }) {
    function formatDated(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    function isDateBetween(dateToCheck, startDate, endDate) {
        const date = new Date(dateToCheck.split("/").reverse().join("-"));
        const start = new Date(startDate.split("/").reverse().join("-"));
        const end = new Date(endDate.split("/").reverse().join("-"));
        return date >= start && date <= end;
    }
    const responsabilidadInput = [
        { label: "Cr", value: 320 },
        { label: "Cc", value: 320 },
        { label: "Sr", value: 300 },
        { label: "Rp", value: 400 },
        { label: "Rp2 (2 ruedas)", value: 401 },
        { label: "Rp3 (3 ruedas)", value: 402 },
        { label: "Rp4 (4 ruedas)", value: 403 },
        { label: "Rt", value: 500 },
        { label: "Ra", value: 550 },
        { label: "Raf", value: 551 },
        { label: "Ip", value: 600 },
        { label: "Dp", value: 800 },
        { label: "Dt", value: 900 },
        { label: "Aseg les", value: 100 },
        { label: "Aseg muerto", value: 130 },
        { label: "3ro les", value: 200 },
        { label: "3ro muerto", value: 230 },
        { label: "3ro muerto Rec", value: 233 },
    ];
    function getLabelsFromValues(dbValue, responsabilidadInput) {
        const valuesArray = dbValue.split(',').map(Number); 
        const labels = valuesArray.map(value => {
            const item = responsabilidadInput.find(item => item.value === value);
            return item ? item.label : null;
        }).filter(label => label !== null);
        return labels.join(', ');
    }
    return (
        <section className='moduloEstadoChoquesTransitorio2 '>
            <div className="div1StroHeader row">
                <div className="col-6 d-flex align-items-center">
                    <label className="labelStro">Fecha Carga</label>
                    <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                        {formatDated(stro.fechaCarga)}
                    </div>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <label className="labelStro">Fecha Sello</label>
                    <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                        {formatDated(stro.fechaDenuncia)}
                    </div>
                </div>
            </div>
            <div className="div1StroHeader2 row">
                <div className="col-6 d-flex align-items-center">
                    <label className="labelStro">Responsabilidad</label>
                    <div className="inputFechaCargaHeaderStro d-flex align-items-center ps-3 textoCarga">
                        {stro?.catStro ? getLabelsFromValues(stro?.catStro, responsabilidadInput) : null}
                    </div>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <label className="labelStro">Vigencia</label>
                    <div className={isDateBetween(fechaStro, qnxInic, qnxFin) ? "btnCoberturaFechaCargaHeaderStro text-center center text-white" : "btnCoberturaFechaCargaHeaderStroRojo text-center center text-white"}>{isDateBetween(fechaStro, qnxInic, qnxFin) ? "Vigencia" : "Sin Vigencia"}</div>
                </div>
            </div>
            <div className="div1StroHeader2 row">
                <div className="d-flex align-items-start">
                    <label className="labelStro pt-1">Observaciones</label>
                    <textarea
                        className="inputFechaCargaHeaderStroObservaciones pt-1 ps-3 ms-2 w-100"
                        value={stro.obsPrevias}
                    />
                </div>
            </div>
        </section>
    );
}
