import { useEffect, useState } from 'react';
import './carga.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
export default function VerTodoDenuncia() {
    const id = useParams().id;
    const [denuncia, setDenuncia] = useState()
    const [cargando, setCargando] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://apifotos.agsseguros.online/api/verTodoEditar/${id}`);
                setDenuncia(resDenuncia.data);
                setCargando(true)
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    return (
        <>
            {cargando ?
                <div>
                    <div className="row">
                        <div className="col">
                            <label className="mb-1">Pza</label>
                            <div className="form-control">{denuncia.pza}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pas</label>
                            <div className="form-control">{denuncia.pas}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pas-Email</label>
                            <div className="form-control">{denuncia.email}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Codigo Productor</label>
                            <div className="form-control">{denuncia.codp}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Tipo Stro</label>
                            <div className="form-control">{denuncia.tipoStro}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>1-FECHA DEL SINIESTRO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">FECHA DENUNCIA</label>
                            <div className="form-control">{formatDate(denuncia.fechaDenuncia)}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">FECHA OCURRENCIA</label>
                            <div className="form-control">{formatDate(denuncia.fechaStro)}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">HORA DE OCURRENCIA</label>
                            <div className="form-control">{denuncia.horaStro}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>2-LUGAR DEL SINIESTRO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Calle</label>
                            <div className="form-control">{denuncia.direccionStroCalle}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Numero</label>
                            <div className="form-control">{denuncia.direccionStroNumero}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <div className="form-control">{denuncia.direccionStroProvincia}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionStroLocalidad}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionStroCP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionStroPais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">INTERSECCION DE/ ENTRE</label>
                            <div className="form-control">{denuncia.interseccionStro}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Y</label>
                            <div className="form-control">{denuncia.interseccionStro2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DETALLES DEL SINIESTRO</label>
                            <div className="form-control">{denuncia.detallesStro}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>3- DATOS DEL CONDUCTOR DEL VEHICULO ASEGURADO</h4>
                    <hr className='mt-3 mb-3' />
                    <div>
                        <label className='mb-1 d-block'>ES EL PROPIO ASEGURADO</label>
                        <div className='form-control'>{denuncia.si}</div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">NOMBRE Y APELLIDO</label>
                            <div className="form-control">{denuncia.nombreConductorAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniConductorAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoConductorAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionConductorAsegDomicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">PROVINCIA</label>
                            <div className="form-control">{denuncia.direccionConductorAsegProvincia}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionConductorAsegLocalidad}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionConductorAsegCP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionConductorAsegPais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">REGISTRO N°</label>
                            <div className="form-control">{denuncia.registroConductorAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">VENCIMIENTO</label>
                            <div className="form-control">{formatDate(denuncia.vencimientoConductorAseg)}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">RELACION CON EL ASEGURADO</label>
                            <div className="form-control">{denuncia.relacionConAseg}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>4-DATOS DEL ASEGURADO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">NOMBRE Y APELLIDO O RAZON SOCIAL</label>
                            <div className="form-control">{denuncia.nombreAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionAsegDomicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">PROVINCIA</label>
                            <div className="form-control">{denuncia.direccionAsegProvincia}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionAsegLocalidad}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionAsegCP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionAsegPais}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>5- DATOS DEL VEHICULO ASEGURADO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">MARCA Y MODELO</label>
                            <div className="form-control">{denuncia.marcaAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO:</label>
                            <div className="form-control">{denuncia.tipoAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DOMINIO</label>
                            <div className="form-control">{denuncia.dom}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">AÑO</label>
                            <div className="form-control">{denuncia.anioAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">N° MOTOR</label>
                            <div className="form-control">{denuncia.motorAseg}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">N° CHASIS</label>
                            <div className="form-control">{denuncia.chasisAseg}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DETALLES DEL SINIESTRO</label>
                            <div className="form-control">{denuncia.detallesDaniosAseg}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>6-DETALLE DEL OTRO VEHICULO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">PROPIETARIO</label>
                            <div className="form-control">{denuncia.propietarioTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionTercero1Domicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <div className="form-control">{denuncia.direccionTercero1Provincia}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionTercero1Localidad}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionTercero1CP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionTercero1Pais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">MARCA</label>
                            <div className="form-control">{denuncia.marcaTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">MODELO</label>
                            <div className="form-control">{denuncia.modeloTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO</label>
                            <div className="form-control">{denuncia.tipoTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DOMINIO</label>
                            <div className="form-control">{denuncia.dominioTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">AÑO</label>
                            <div className="form-control">{denuncia.anioTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">NÚMERO MOTOR</label>
                            <div className="form-control">{denuncia.motorTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">NÚMERO CHASIS</label>
                            <div className="form-control">{denuncia.chasisTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">COMPAÑIA</label>
                            <div className="form-control">{denuncia.ciaTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">NÚMERO POLIZA</label>
                            <div className="form-control">{denuncia.polizaTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                            <div className="form-control">{denuncia.danioTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CONDUCTOR</label>
                            <div className="form-control">{denuncia.conductorTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniConductorTercero1}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoConductorTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionConductorTercero1Domicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionConductorTercero1Localidad}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <div className="form-control">{denuncia.direccionConductorTercero1Provincia}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionConductorTercero1CP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionConductorTercero1Pais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Compañia</label>
                            <div className="form-control">{denuncia.ciaConductorTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">N° POLIZA</label>
                            <div className="form-control">{denuncia.polizaConductorTercero1}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">ESTADO CIVIL</label>
                            <div className="form-control">{denuncia.estadoCivilConductorTercero1}</div>
                        </div>
                        <div className="col-3">
                            <label className="mb-1">FECHA NACIMIENTO</label>
                            <div className="form-control">{formatDate(denuncia.fechaNacConductorTercero1)}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CONDUCTOR HABITUAL DEL VEHICULO?</label>
                            <div className='d-flex'>
                                <input type="checkbox" checked={denuncia.si1} disabled />
                                <label className='d-block ms-2'>SI_</label>
                            </div>
                            <div className='d-flex'>
                                <input type="checkbox" checked={denuncia.no} disabled />
                                <label className=' d-block ms-2'>NO_</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">REGISTRO N°</label>
                            <div className="form-control">{denuncia.registroTercero1}</div>
                        </div>
                        <div className="col-3">
                            <label className="mb-1">VENCIMIENTO</label>
                            <div className="form-control">{formatDate(denuncia.vencimientoTercero1)}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>7- DETALLE DEL OTRO VEHICULO</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">PROPIETARIO</label>
                            <div className="form-control">{denuncia.propietarioTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoTercero2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionTercero2Domicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <div className="form-control">{denuncia.direccionTercero2Provincia}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionTercero2Localidad}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionTercero2CP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionTercero2Pais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">MARCA</label>
                            <div className="form-control">{denuncia.marcaTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">MODELO</label>
                            <div className="form-control">{denuncia.modeloTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO</label>
                            <div className="form-control">{denuncia.tipoTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">DOMINIO</label>
                            <div className="form-control">{denuncia.dominioTercero2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">AÑO</label>
                            <div className="form-control">{denuncia.anioTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">NÚMERO MOTOR</label>
                            <div className="form-control">{denuncia.motorTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">NÚMERO CHASIS</label>
                            <div className="form-control">{denuncia.chasisTercero2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                            <textarea className="form-control" disabled value={denuncia.detallesDanioTercero2} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CONDUCTOR</label>
                            <div className="form-control">{denuncia.conductorTercero2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <div className="form-control">{denuncia.dniConductorTercero2}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <div className="form-control">{denuncia.telefonoConductorTercero2}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <div className="form-control">{denuncia.direccionConductorTercero2Domicilio}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <div className="form-control">{denuncia.direccionConductorTercero2Provincia}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <div className="form-control">{denuncia.direccionConductorTercero2Localidad}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <div className="form-control">{denuncia.direccionConductorTercero2CP}</div>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <div className="form-control">{denuncia.direccionConductorTercero2Pais}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">ESTADO CIVIL</label>
                            <div className="form-control">{denuncia.estadoCivilConductorTercero2}</div>
                        </div>
                        <div className="col-3">
                            <label className="mb-1">FECHA NACIMIENTO</label>
                            <div className="form-control">{formatDate(denuncia.fechaNacimientoConductorTercero2)}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CONDUCTOR HABITUAL DEL VEHICULO?</label>
                            <div className="form-control">
                                <input type="checkbox" disabled checked={denuncia.conductorHabitual} />
                                <label className="ms-2">Conductor Habitual</label>
                            </div>
                            <p>Dejar destildado en caso contrario</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">REGISTRO N°</label>
                            <div className="form-control">{denuncia.registroTercero2}</div>
                        </div>
                        <div className="col-3">
                            <label className="mb-1">VENCIMIENTO</label>
                            <div className="form-control">{formatDate(denuncia.vencimientoTercero2)}</div>
                        </div>
                    </div>
                    <h4 className='mt-5'>8-DAÑOS MATERIALES A COSAS</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">PROPIETARIO</label>
                            <p className="form-control">{denuncia.propietarioMat}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <p className="form-control">{denuncia.dniMat}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <p className="form-control">{denuncia.telefonoMat}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <p className="form-control">{denuncia.direccionMatDomicilio}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <p className="form-control">{denuncia.direccionMatProvincia}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <p className="form-control">{denuncia.direccionMatLocalidad}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <p className="form-control">{denuncia.direccionMatCP}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <p className="form-control">{denuncia.direccionMatPais}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">DETALLES LOS DAÑOS DEL VEHICULO</label>
                            <p className="form-control">{denuncia.detalleDaniosMat}</p>
                        </div>
                    </div>

                    <h4 className='mt-5'>9-LESIONES A TERCEROS</h4>
                    <hr className='mt-3 mb-3' />
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">NOMBRE Y APELLIDO</label>
                            <p className="form-control">{denuncia.nombreLesTercero}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO Y N° DOCUMENTO</label>
                            <p className="form-control">{denuncia.dniLesTercero}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">TELEFONO</label>
                            <p className="form-control">{denuncia.telefonoLesTercero}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">Domicilio</label>
                            <p className="form-control">{denuncia.direccionLesTerceroDomicilio}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">Provincia</label>
                            <p className="form-control">{denuncia.direccionLesTerceroProvincia}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">Localidad</label>
                            <p className="form-control">{denuncia.direccionLesTerceroLocalidad}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CP</label>
                            <p className="form-control">{denuncia.direccionLesTerceroCP}</p>
                        </div>
                        <div className="col">
                            <label className="mb-1">Pais</label>
                            <p className="form-control">{denuncia.direccionLesTerceroPais}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">ESTADO CIVIL</label>
                            <p className="form-control">{denuncia.estadoCivilTercero}</p>
                        </div>
                        <div className="col-3">
                            <label className="mb-1">FECHA NACIMIENTO</label>
                            <p className="form-control">{formatDate(denuncia.fechaNacimientoLesTercero)}</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">RELACION CON EL ASEGURADO</label>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    checked={denuncia.conductorOtroVehiculo}
                                    disabled
                                />
                                <label className="d-block ms-2">CONDUCTOR OTRO VEHICULO</label>
                            </div>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    checked={denuncia.pasajeroVehiculoAsegurado}
                                    disabled
                                />
                                <label className="d-block ms-2">PASAJERO DE VEHICULO ASEGURADO</label>
                            </div>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    checked={denuncia.pasajeroOtroVehiculo}
                                    disabled
                                />
                                <label className="d-block ms-2">PASAJERO OTRO VEHICULO</label>
                            </div>
                            <div className="d-flex">
                                <input type="checkbox" checked={denuncia.peaton} disabled />
                                <label className="d-block ms-2">PEATON</label>
                            </div>
                        </div>
                        <div className="col">
                            <label className="mb-1">TIPO DE LESIONES</label>
                            <div className="d-flex">
                                <input type="checkbox" checked={denuncia.tipoLesion} disabled />
                                <label className="d-block ms-2">LEVES</label>
                            </div>
                            <div className="d-flex">
                                <input type="checkbox" checked={denuncia.tipoLesion} disabled />
                                <label className="d-block ms-2">GRAVE</label>
                            </div>
                            <div className="d-flex">
                                <input type="checkbox" checked={denuncia.tipoLesion} disabled />
                                <label className="d-block ms-2">MORTAL</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <label className="mb-1">CENTRO ASISTENCIAL</label>
                            <p className="form-control">{denuncia.centroAsistencial}</p>
                        </div>
                    </div>
                </div> : <div className="center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </>
    );
}