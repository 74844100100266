import { Document, Text, Page, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        backgroundColor: "white",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    bordeNegro: {
        borderBottom: "2px solid black",
        width: "100%",
        marginTop: "8px",
    },
    bordeNegro1: {
        borderBottom: "2px solid black",
        width: "100%",
        marginTop: "1.5px",
        marginBottom: "3px",
    },
    flexCentradoContenedor: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
    marginTop1: {
        marginTop: "20px",
    },
    marginTop2: {
        marginTop: "10px",
    },
    marginTop3: {
        marginTop: "5px",
    },
    titulo1: {
        fontSize: "12px",
    },
    contenedorSeccion1: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    columnSeccion1: {
        flex: 1,
    },
    columnSeccion1A: {
        flex: 2,
    },
    letra1: {
        fontSize: "10px",
    },
    left50: {
        marginLeft: '62%'
    }
});
function PDF({ sicobe, pagosAnteriores, cobert, denunciaParaSicobe }) {
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };
    const cbtSicobe = [
        { value: 1, label: "CON COBERTURA", key: 1 },
        { value: 2, label: "EN REVISION", key: 2 },
        { value: 3, label: "CON COBERTURA ANALIZADO POR COBRANZAS", key: 3 },
        { value: 4, label: "SIN COBERTURA ANALIZADO POR COBRANZAS", key: 4 },
        { value: 5, label: "PARA IMPUTAR", key: 5 },
    ]
    return (
        <Document>
            <Page style={styles.page} size={"A4"}>
                <View style={styles.flexCentradoContenedor}><Text style={styles.titulo1}>COBERTURA DE SINIESTROS POR COBRANZAS</Text></View>
                <View style={[styles.contenedorSeccion1, styles.marginTop1]}>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{denunciaParaSicobe ? `NRO-STRO: ${denunciaParaSicobe}` : `NRO-STRO: ${sicobe?.nroStro?.toString().substring(3)}`}</Text>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{`VEN-RECH: ${formatDate(addDays(sicobe?.fechaStro, 28))}`}</Text>
                    <Text style={[styles.letra1, styles.columnSeccion1A]}>{`PAS: ${sicobe?.productor?.pro_raz_social}`}</Text>
                </View>
                <View style={[styles.contenedorSeccion1, styles.marginTop2]}>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{`FEC-STRO: ${formatDate(sicobe?.fechaStro)}`}</Text>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{`FEC-DEN: ${formatDate(sicobe?.fechaDenuncia)}`}{" "}</Text>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{`PZA: ${sicobe?.pza}`}</Text>
                    <Text style={[styles.letra1, styles.columnSeccion1]}>{`PROP: ${sicobe?.qnx?.ados_nro_cc}`}</Text>
                </View>
                <Text style={styles.bordeNegro}></Text>
                <Text style={styles.bordeNegro1}></Text>
                <Text style={[styles.letra1, styles.marginTop2]}>
                    {`AGENCIA REAL: ${sicobe?.productor?.pro_productor ? sicobe.productor.pro_productor : ''
                        }${sicobe?.productor?.pro_productor && sicobe?.productor?.pro_raz_social ? ' - ' : ''}${sicobe?.productor?.pro_raz_social ? sicobe.productor.pro_raz_social : ''
                        }${sicobe?.productor?.pro_raz_social && sicobe?.productor?.pro_cod_sup ? ' - ' : ''}${sicobe?.productor?.pro_cod_sup ? sicobe.productor.pro_cod_sup : ''
                        }`}
                </Text>
                <Text style={[styles.letra1, styles.marginTop2]}>{`ASEGURADO: ${sicobe?.qnx?.ados_nom_aseg}`}</Text>
                <Text style={[styles.letra1, styles.marginTop2]}>{`DOMICILIO: ${sicobe?.qnx?.ados_dir_aseg} - ${sicobe?.qnx?.ados_loc_aseg}`}</Text>
                <Text style={styles.bordeNegro}></Text>
                <Text style={styles.bordeNegro1}></Text>
                <Text style={[styles.letra1, styles.marginTop2]}>{`FEC-EMISION: ${formatDate(sicobe?.qnx?.ados_fech_emi)}`}</Text>
                <Text style={[styles.letra1, styles.marginTop2]}>{`INI-VIGENCIA: ${formatDate(sicobe?.qnx?.ados_fech_ini)}`}</Text>
                <Text style={[styles.letra1, styles.marginTop2]}>{`FIN-VIGENCIA: ${formatDate(sicobe?.qnx?.ados_fech_fin)}`}</Text>
                <Text style={styles.bordeNegro}></Text>
                <Text style={styles.bordeNegro1}></Text>
                <View style={[styles.flexCentradoContenedor, styles.marginTop2]}><Text style={styles.titulo1}>PLAN DE PAGOS</Text></View>
                <View style={[styles.contenedorSeccion1, styles.marginTop1]}>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Fecha We:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Fecha Me:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Imp Cuo:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Imp Tot:`}</Text>
                    </View>
                </View>
                <View style={[styles.contenedorSeccion1, styles.marginTop3]}>
                    <View style={[styles.columnSeccion1]}>
                        {sicobe.fechaLimiteWe.slice(0, -1).map((pago, index) => (
                            <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${formatDate(pago)} **` : `${formatDate(pago)}`}</Text>
                        ))}
                    </View>
                    <View style={[styles.columnSeccion1]}>
                        {sicobe.fechaLimiteMe.slice(0, -1).map((pago, index) => (
                            <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${formatDate(pago)} **` : `${formatDate(pago)}`}</Text>
                        ))}
                    </View>
                    {sicobe.pagos.length > 0 ?
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.fechaLimiteMe.slice(0, -1).map((pago, index) => (
                                <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${sicobe?.pagos[0]?.pga_importe} **` : `${sicobe?.pagos[0]?.pga_importe}`}</Text>
                            ))}
                        </View> : <View style={[styles.columnSeccion1]}></View>}
                    {sicobe.pagos.length > 0 ?
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.fechaLimiteMe.slice(0, -1).map((pago, index) => (
                                <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${(sicobe.pagos[0].pga_importe * (index + 1)).toFixed(2)} **` : `${(sicobe.pagos[0].pga_importe * (index + 1)).toFixed(2)}`}</Text>
                            ))}
                        </View> : <View style={[styles.columnSeccion1]}></View>}
                </View>
                <Text style={styles.bordeNegro}></Text>
                <Text style={styles.bordeNegro1}></Text>
                <View style={[styles.flexCentradoContenedor, styles.marginTop2]}><Text style={styles.titulo1}>PAGOS</Text></View>
                <View style={[styles.contenedorSeccion1, styles.marginTop1]}>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`MP:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Fecha:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Imp Cuo:`}</Text>
                    </View>
                    <View style={[styles.columnSeccion1, styles.contenedorSeccion1]}>
                        <Text style={[styles.letra1, styles.columnSeccion1]}>{`Imp Tot:`}</Text>
                    </View>
                </View>
                {sicobe.pagos.length > 0 ?
                    <View style={[styles.contenedorSeccion1, styles.marginTop3]}>
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.pagos.map((pago, index) => (
                                <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${pago.pga_mp} **` : `${pago.pga_mp}`}</Text>
                            ))}
                        </View>
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.pagos.map((pago, index) => (
                                <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${formatDate(pago.pga_fliqags)} **` : `${formatDate(pago.pga_fliqags)}`}</Text>
                            ))}
                        </View>
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.pagos.map((pago, index) => (
                                <Text style={[styles.letra1,]}>{index === pagosAnteriores.length - 1 ? `${pago.pga_importe} **` : `${pago.pga_importe}`}</Text>
                            ))}
                        </View>
                        <View style={[styles.columnSeccion1]}>
                            {sicobe.pagos.map((pago, index) => {
                                const importeAcumulado = sicobe.pagos.slice(0, index + 1).reduce((acc, curr) => acc + parseFloat(curr.pga_importe), 0);
                                return (<Text style={[styles.letra1]} key={index}>{index === pagosAnteriores.length - 1 ? `${importeAcumulado.toFixed(2)} **` : `${importeAcumulado.toFixed(2)}`}</Text>);
                            })}
                        </View>
                    </View> : <></>}
                <Text style={styles.bordeNegro}></Text>
                <Text style={styles.bordeNegro1}></Text>
                <View style={[styles.flexCentradoContenedor, styles.marginTop2]}><Text style={styles.titulo1}>{sicobe.cbtSicobe > 0 ? cbtSicobe.find(item => item.value == sicobe.cbtSicobe).label : cobert}</Text></View>
                <View><Text style={[styles.letra1, styles.left50, styles.marginTop2]}>{sicobe.pagos.length > 0 ? `Total Pagado: ${(sicobe.pagos.reduce((acc, curr) => acc + parseFloat(curr.pga_importe), 0)).toFixed(2)}` : 'Total Pagado:'}</Text></View>
                <View>
                    <Text style={[styles.letra1, styles.left50, styles.marginTop3]}>
                        {sicobe.pagos.length > 0 ? `Importe pagado a la fecha de Stro: ${pagosAnteriores.length > 0
                            ? sicobe.pagos.slice(0, pagosAnteriores.length).filter(pago => !isNaN(parseFloat(pago.pga_importe))).reduce((acc, curr) => acc + parseFloat(curr.pga_importe), 0).toFixed(2)
                            : ''
                            }` : 'Importe pagado a la fecha de Stro'}
                    </Text>
                </View>
                <View style={styles.pageNumber}>
                    <Text
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber}/${totalPages}`
                        }
                    />
                </View>{" "}
            </Page>
        </Document>
    );
}

export default PDF;
