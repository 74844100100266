import { useCallback, useState } from "react";
import "./modulosGeneralesCss.css";
import { useCookies } from 'react-cookie';
import axios from "axios";
export default function ModuloActualizacionStro({ denuncia }) {
    const [opacity, setOpacity] = useState(0)
    const [cookies] = useCookies(["user"], ['asignado']);
    const [selectedOption, setSelectedOption] = useState(denuncia.empleadoAsignado);
    const [estadoOption, setEstadoOption] = useState(denuncia.estado);
    const [fechaRec, setFechaRec] = useState(denuncia.recepcionRecChoques)
    const [sicobeOption, setSicobeOption] = useState(denuncia.cbtSicobe);
    const [valoresContractuales, setValoresContractuales] = useState({ monto: denuncia.monto, comercial: denuncia.comercial, porcentaje: denuncia.porcentaje, aceptacion: denuncia.aceptacion, ordenDePago: denuncia.ordenDePago })
    const [responsabilidad, setResponsabilidad] = useState(denuncia.catStro)
    const [descStro, setDescStro] = useState(denuncia.descStro)
    const [observaciones, setObservaciones] = useState('')
    const [prioridad, setPrioridad] = useState(denuncia.prioridad)
    const [vencimiento, setVencimiento] = useState(denuncia.fechaVencimiento)
    // Funcion Transformar Fecha
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    const formattedDate = day + '/' + month + '/' + year;
    function getCurrentTime() {
        const now = new Date();
        let hour = now.getHours();
        let minute = now.getMinutes();
        minute = (minute < 10) ? '0' + minute : minute;
        return hour + ':' + minute;
    }
    const currentTime = getCurrentTime();
    // Array de estados y asignados 
    const optionsCarga = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "StellaM", label: "StellaM", key: 3 },
        { value: "DanielaM", label: "DanielaM", key: 4 },
    ];
    const optionsChoque = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
    ];
    const optionsContractuales = [
        { value: "JuanG", label: "JuanG", key: 1 },
        { value: "LucaP", label: "LucaP", key: 2 },
        { value: "DanielaM", label: "DanielaM", key: 3 },
        { value: "DiegoD", label: "DiegoD", key: 4 },
        { value: "DiegoS", label: "DiegoS", key: 5 },
        { value: "OrelyC", label: "OrelyC", key: 6 },
        { value: "StellaM", label: "StellaM", key: 7 },
        { value: "LucianaF", label: "LucianaF", key: 8 },
        { value: "CandelaS", label: "CandelaS", key: 9 },
        { value: "MagaliG", label: "MagaliG", key: 10 },
        { value: "PilarS", label: "PilarS", key: 11 },
        { value: "Augusto", label: "Augusto", key: 12 }
    ];
    const estados = [
        { value: 1, label: 'CARGADO', tipoStro: 'GENERAL' },
        { value: 2, label: 'CERRADO', tipoStro: 'GENERAL' },


        { value: 101, label: 'RR', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 102, label: 'CTZ', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 103, label: 'ASIG', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 110, label: 'ACIA', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 104, label: 'RCIA', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 105, label: 'IN/REC', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 106, label: 'TER', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 107, label: 'CER', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 108, label: 'ARCH', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 109, label: 'LEGALES', tipoStro: 'ACCIDENTE DE TRANSITO' },
        { value: 111, label: "DOC EN OFICINA", tipoStro: "ACCIDENTE DE TRANSITO" },


        { value: 201, label: 'EN PROCESO', tipoStro: 'ROBO TOTAL' },
        { value: 202, label: 'ESPERA INFORME INVESTIGACIÓN', tipoStro: 'ROBO TOTAL' },
        { value: 203, label: 'ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'ROBO TOTAL' },
        { value: 204, label: 'ESPERA BAJA', tipoStro: 'ROBO TOTAL' },
        { value: 205, label: 'BAJA A GESTOR', tipoStro: 'ROBO TOTAL' },
        { value: 206, label: 'BAJA DEVUELTA POR GESTOR', tipoStro: 'ROBO TOTAL' },
        { value: 207, label: 'ESTADO BAJA', tipoStro: 'ROBO TOTAL' },
        { value: 208, label: 'EN PROCESO JJ', tipoStro: 'ROBO TOTAL' },
        { value: 209, label: 'EN LIQUIDACIÓN', tipoStro: 'ROBO TOTAL' },
        { value: 210, label: 'EN ESPERA ACEPTACIÓN', tipoStro: 'ROBO TOTAL' },
        { value: 211, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'ROBO TOTAL' },
        { value: 212, label: 'TERMINADO RECHAZADO', tipoStro: 'ROBO TOTAL' },
        { value: 213, label: 'LEGALES', tipoStro: 'ROBO TOTAL' },


        { value: 301, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'ROBO PARCIAL' },
        { value: 302, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'ROBO PARCIAL' },
        { value: 303, label: 'EN PROCESO', tipoStro: 'ROBO PARCIAL' },
        { value: 310, label: 'ARCHIVO', tipoStro: 'ROBO PARCIAL' },
        { value: 304, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'ROBO PARCIAL' },
        { value: 305, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'ROBO PARCIAL' },
        { value: 306, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'ROBO PARCIAL' },
        { value: 307, label: 'TERMINADO A TESORERÍA', tipoStro: 'ROBO PARCIAL' },
        { value: 308, label: 'TERMINADO RECHAZADO', tipoStro: 'ROBO PARCIAL' },
        { value: 309, label: 'LEGALES', tipoStro: 'ROBO PARCIAL' },


        { value: 401, label: 'EN ESPERA INFORME INSPECCIÓN', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 402, label: 'EN ESPERA INFORME INVESTIGACIÓN', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 403, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 404, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 405, label: 'EN PROCESO', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 412, label: 'ARCHIVO', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 406, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 407, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 408, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 409, label: 'TERMINADO A TESORERÍA', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 410, label: 'TERMINADO RECHAZADO', tipoStro: 'ROBO PARCIAL INSP' },
        { value: 411, label: 'LEGALES', tipoStro: 'ROBO PARCIAL INSP' },



        { value: 501, label: 'EN ESPERA INFORME INSPECCIÓN', tipoStro: 'INCENDIO TOTAL' },
        { value: 502, label: 'EN ESPERA INFORME INVESTIGACIÓN', tipoStro: 'INCENDIO TOTAL' },
        { value: 503, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'INCENDIO TOTAL' },
        { value: 504, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'INCENDIO TOTAL' },
        { value: 505, label: 'EN PROCESO', tipoStro: 'INCENDIO TOTAL' },
        { value: 512, label: 'ARCHIVO', tipoStro: 'INCENDIO TOTAL' },
        { value: 506, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'INCENDIO TOTAL' },
        { value: 507, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'INCENDIO TOTAL' },
        { value: 508, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'INCENDIO TOTAL' },
        { value: 509, label: 'TERMINADO A TESORERÍA', tipoStro: 'INCENDIO TOTAL' },
        { value: 510, label: 'TERMINADO RECHAZADO', tipoStro: 'INCENDIO TOTAL' },
        { value: 511, label: 'LEGALES', tipoStro: 'INCENDIO TOTAL' },


        { value: 601, label: 'EN ESPERA INFORME INSPECCIÓN', tipoStro: 'INCENDIO PARCIAL' },
        { value: 602, label: 'EN ESPERA INFORME INVESTIGACIÓN', tipoStro: 'INCENDIO PARCIAL' },
        { value: 603, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'INCENDIO PARCIAL' },
        { value: 604, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'INCENDIO PARCIAL' },
        { value: 605, label: 'EN PROCESO', tipoStro: 'INCENDIO PARCIAL' },
        { value: 612, label: 'ARCHIVO', tipoStro: 'INCENDIO PARCIAL' },
        { value: 606, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'INCENDIO PARCIAL' },
        { value: 607, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'INCENDIO PARCIAL' },
        { value: 608, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'INCENDIO PARCIAL' },
        { value: 609, label: 'TERMINADO A TESORERÍA', tipoStro: 'INCENDIO PARCIAL' },
        { value: 610, label: 'TERMINADO RECHAZADO', tipoStro: 'INCENDIO PARCIAL' },
        { value: 611, label: 'LEGALES', tipoStro: 'INCENDIO PARCIAL' },


        { value: 701, label: 'EN ESPERA INFORME INSPECCIÓN', tipoStro: 'DAÑO TOTAL' },
        { value: 702, label: 'EN ESPERA INFORME INVESTIGACIÓN', tipoStro: 'DAÑO TOTAL' },
        { value: 703, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'DAÑO TOTAL' },
        { value: 704, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'DAÑO TOTAL' },
        { value: 705, label: 'EN PROCESO', tipoStro: 'DAÑO TOTAL' },
        { value: 712, label: 'ARCHIVO', tipoStro: 'DAÑO TOTAL' },
        { value: 706, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'DAÑO TOTAL' },
        { value: 707, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'DAÑO TOTAL' },
        { value: 708, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'DAÑO TOTAL' },
        { value: 709, label: 'TERMINADO A TESORERÍA', tipoStro: 'DAÑO TOTAL' },
        { value: 710, label: 'TERMINADO RECHAZADO', tipoStro: 'DAÑO TOTAL' },
        { value: 711, label: 'LEGALES', tipoStro: 'DAÑO TOTAL' },


        { value: 801, label: 'EN ESPERA INFORME INSPECCIÓN', tipoStro: 'DAÑO PARCIAL' },
        { value: 802, label: 'EN ESPERA INFORME INVESTIGACIÓN', tipoStro: 'DAÑO PARCIAL' },
        { value: 803, label: 'EN ESPERA DOCUMENTACIÓN PRODUCTOR', tipoStro: 'DAÑO PARCIAL' },
        { value: 804, label: 'ESPERA FACTURA PROVEEDOR', tipoStro: 'DAÑO PARCIAL' },
        { value: 805, label: 'EN PROCESO', tipoStro: 'DAÑO PARCIAL' },
        { value: 812, label: 'ARCHIVO', tipoStro: 'DAÑO PARCIAL' },
        { value: 806, label: 'ARCHIVO ESPERA ACEPTACIÓN', tipoStro: 'DAÑO PARCIAL' },
        { value: 807, label: 'ARCHIVO FACTURA PROVEEDOR', tipoStro: 'DAÑO PARCIAL' },
        { value: 808, label: 'TERMINADO DESCUENTO A MORÓN', tipoStro: 'DAÑO PARCIAL' },
        { value: 809, label: 'TERMINADO A TESORERÍA', tipoStro: 'DAÑO PARCIAL' },
        { value: 810, label: 'TERMINADO RECHAZADO', tipoStro: 'DAÑO PARCIAL' },
        { value: 811, label: 'LEGALES', tipoStro: 'DAÑO PARCIAL' },
    ];
    // Fin
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    //
    const handleChangeEstado = (event) => {
        setEstadoOption(event.target.value);
    };
    const optionsEstado = denuncia.catStro ? estados.filter(e => e.tipoStro === denuncia.tipoStro || e.tipoStro === 'GENERAL') : estados.filter(e => e.tipoStro === 'GENERAL')
    const estadoSeleccionado = estados.find(estado => estado.value === Number(estadoOption));
    const optionsPrioridad = [{ label: 'NINGUNA', value: 0 }, { label: 'BAJA', value: 1 }, { label: 'MEDIA', value: 2 }, { label: 'ALTA', value: 3 }]
    const prioridadSeleccionado = optionsPrioridad.find(p => p.value === Number(prioridad));
    // 
    const cbtSicobe = [
        { value: 1, label: "CON COBERTURA", key: 1 },
        { value: 2, label: "EN REVISION", key: 2 },
        { value: 3, label: "CON COBERTURA ANALIZADO POR COBRANZAS", key: 3 },
        { value: 4, label: "SIN COBERTURA ANALIZADO POR COBRANZAS", key: 4 },
        { value: 5, label: "PARA IMPUTAR", key: 5 },
    ]
    const handleChangeSicobe = (event) => {
        setSicobeOption(event.target.value);
    };
    //
    const handleChangePrioridad = (event) => {
        setPrioridad(event.target.value);
    };
    //
    const actualizarValores = useCallback(async (e) => {
        e.preventDefault()
        setOpacity(1)
        await axios.post(`https://apifotos.agsseguros.online/api/actualizarDescStro`, { id: denuncia.id, desc: descStro })
        if (selectedOption !== denuncia.empleadoAsignado) {
            await axios.post(`https://apifotos.agsseguros.online/api/actualizarAsignadoStro`, { id: denuncia.id, asignado: selectedOption }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/mail/mailAsignado`,
                    {
                        id: denuncia.id,
                        user: selectedOption,
                        nroStro: denuncia.nroStro,
                        entryId: denuncia.entryId
                    })
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `El usuario ${cookies.user.usuario} ha asignado el stro a ${selectedOption}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (estadoOption !== denuncia.estado) {
            await axios.post(`https://apifotos.agsseguros.online/api/actualizarEstadoStro`, { stro: denuncia.id, estado: estadoOption }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `El usuario ${cookies.user.usuario} ha cambiado el estado a ${estadoSeleccionado.label}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (fechaRec !== denuncia.recepcionRecChoques) {
            await axios.post(`https://apifotos.agsseguros.online/api/recepcionReChoques`, { id: denuncia.id, fecha: fechaRec }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `Se ha recepcionado el reclamo con fecha ${fechaRec}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (prioridad !== denuncia.prioridad) {
            await axios.post(`https://apifotos.agsseguros.online/api/actualizarPrioridadStro`, { stro: denuncia.id, prioridad: prioridad }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `El usuario ${cookies.user.usuario} ha cambiado la prioridad a ${prioridadSeleccionado.label}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (vencimiento !== denuncia.fechaVencimiento) {
            await axios.post(`https://apifotos.agsseguros.online/api/fechaVencimiento`, { id: denuncia.id, fechaVencimiento: vencimiento }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `Se ha mmodificado la fecha vencimiento con fecha ${vencimiento}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (sicobeOption !== denuncia.cbtSicobe) {
            await axios.post(`https://apifotos.agsseguros.online/api/actualizarSicobe`, { id: denuncia.id, sicobe: sicobeOption }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `El usuario ${cookies.user.usuario} ha actualizado el valor de sicobe a ${cbtSicobe[sicobeOption - 1].label}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (responsabilidad !== denuncia.catStro) {
            await axios.post(`https://apifotos.agsseguros.online/api/actualizarResponsabilidad`, { id: denuncia.id, responsabilidad: responsabilidad }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`,
                    {
                        id: denuncia.id,
                        desc: `El usuario ${cookies.user.usuario} ha actualizado la responsabilidad a ${responsabilidad}`,
                        obs: observaciones,
                        jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                        motivo: 'STRO',
                        autor: cookies.user.usuario,
                        fecha: formattedDate,
                        hora: currentTime
                    })
            })
        }
        if (valoresContractuales.monto !== denuncia.monto || valoresContractuales.comercial !== denuncia.comercial || valoresContractuales.porcentaje !== denuncia.porcentaje || valoresContractuales.aceptacion !== denuncia.aceptacion || valoresContractuales.ordenDePago !== denuncia.ordenDePago) {
            const changedFields = [];
            const changeDetails = [];
            for (const key in valoresContractuales) {
                if (valoresContractuales.hasOwnProperty(key) && valoresContractuales[key] !== denuncia[key]) {
                    changedFields.push(key);
                    changeDetails.push(`${key}: ${valoresContractuales[key]}`);
                }
            }
            await axios.post(`https://apifotos.agsseguros.online/api/contractuales/actualizarValoresContractuales`, { id: denuncia.id, monto: valoresContractuales.monto, comercial: valoresContractuales.comercial, porcentaje: valoresContractuales.porcentaje, aceptacion: valoresContractuales.aceptacion, ordenDePago: valoresContractuales.ordenDePago }).then(async (response) => {
                await axios.post(`https://apifotos.agsseguros.online/api/cronologia`, {
                    id: denuncia.id,
                    desc: `El usuario ${cookies.user.usuario} ha actualizado los siguientes valores: ${changeDetails.join(', ')}`,
                    obs: observaciones,
                    jerarquia: cookies.user.depto === 'cargas' ? 'CRG' : 'DEN',
                    motivo: 'STRO',
                    autor: cookies.user.usuario,
                    fecha: formattedDate,
                    hora: currentTime
                });
            })
        }
        window.location.reload()
    }, [denuncia, cookies, formattedDate, currentTime, selectedOption, estadoOption, estadoSeleccionado, sicobeOption, valoresContractuales, observaciones, descStro, responsabilidad, vencimiento, prioridad]);
    return (
        <>
            <section className="moduloActualizacionContainer" style={{ opacity: opacity === 1 ? 0.25 : 1 }}>
                {opacity === 1 ? <div className="SpinnerInTheMiddle"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div> : <></>}
                <div className="rowModuloActualizacion">
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro"></label>
                        <div className="selectAsignadoDentroStro d-flex align-items-center text-white">
                            <select value={selectedOption} onChange={handleChange} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value="">Asignar A</option>
                                {cookies.user.depto === 'cargas' ? optionsCarga.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'choques' ? optionsChoque.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : (cookies.user.depto === 'contractuales' ? optionsContractuales.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>)) : <></>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro"></label>
                        <div className="selectAsignadoDentroStro d-flex align-items-center text-white">
                            <select value={estadoOption} onChange={handleChangeEstado} className="select" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value="">Estado</option>
                                {optionsEstado.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`F. Rec Recibido`}</label>
                        <input type="date" value={fechaRec} onChange={(e) => setFechaRec(e.target.value)} className="inputModuloActualizacion" disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Cbt Sicobe</label>
                        <div className="selectSicobeDentroStro">
                            <select value={sicobeOption} onChange={handleChangeSicobe} className="select2A" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value=""></option>
                                {cbtSicobe.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Responsabilidad</label>
                        <input type="text" value={responsabilidad} className="inputModuloActualizacion" onChange={(e) => setResponsabilidad(e.target.value)} disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                </div>
                <div className="rowModuloActualizacion">
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Monto</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.monto} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, monto: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Comercial</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.comercial} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, comercial: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Pocentaje</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.porcentaje} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, porcentaje: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Aceptacion</label>
                        <input type="text" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.aceptacion} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, aceptacion: e.target.value }))} />
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">Orden de Pago</label>
                        <input type="number" disabled={cookies?.user?.depto === 'emision' ? true : false} value={valoresContractuales.ordenDePago} className="inputModuloActualizacion" onChange={(e) => setValoresContractuales((prevState) => ({ ...prevState, ordenDePago: e.target.value }))} />
                    </div>
                </div>
                <div className="rowModuloActualizacion1">
                    <div className="colModuloActualizacion w-100">
                        <label htmlFor="" className="labelStro">{`Observaciones`}</label>
                        <input type="text" className="inputModuloActualizacion" disabled={cookies?.user?.depto === 'emision' ? true : false} onChange={(e) => setObservaciones(e.target.value)}></input>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`Prioridad`}</label>
                        <div className="selectSicobeDentroStro">
                            <select value={prioridad} onChange={handleChangePrioridad} className="select2A" disabled={cookies?.user?.depto === 'emision' ? true : false}>
                                <option value=""></option>
                                {optionsPrioridad.map((option) => (<option key={option.key} value={option.value}>{option.label}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className="colModuloActualizacion">
                        <label htmlFor="" className="labelStro">{`Vencimiento`}</label>
                        <input type="date" value={vencimiento} onChange={(e) => setVencimiento(e.target.value)} className="inputModuloActualizacion" disabled={cookies?.user?.depto === 'emision' ? true : false} />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="w-100 me-2">
                        <label htmlFor="" className="labelStro">Descripcion Stro</label>
                        <textarea className="inputModuloActualizacionDesc" onChange={(e) => setDescStro(e.target.value)} value={descStro} />
                    </div>
                    <div className="">
                        <button className="btnActualizarModuloActualizacion text-white" disabled={cookies?.user?.depto === 'emision' ? true : false} onClick={(e) => actualizarValores(e)}>ACTUALIZAR</button>
                    </div>
                </div>
            </section>
        </>
    );
}
