import { Document, Text, Page, StyleSheet, View, Font } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "30px",
        backgroundColor: "white",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    spacer: {
        width: 20, 
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left'
    },
    titulo: {
        fontSize: '12px',
        fontWeight: 'bold'
    },
    titulo2: {
        fontSize: '11px',
        fontWeight: 400
    },
    marginText: {
        marginLeft: '5px'
    },
    row: {
        margin: '0 auto',
        display: 'flex',
        flexwrap: 'wrap',
    },
    col4: {
        flex: '0 0 33.33%', 
        maxWidth: '33.33%', 
    },
    marginTop: {
        marginTop: '3px'
    },
    marginTop1: {
        marginTop: '1px'
    },
    bordeNegro: {
        height: '2px',
        backgroundColor: 'black',
        width: '100%',
        marginTop: '6px',
        marginBottom: '6px'
    }
});

function FrentePdf({ denuncia, denunciaParaSicobe }) {
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const ivaNros = [{ id: 1, NOMBRE: 'CONSUMIDOR FINAL' }, { id: 2, NOMBRE: 'LIBERADO' }, { id: 3, NOMBRE: 'NO RESP/EXENTO' }, { id: 4, NOMBRE: 'RESP NO INSCRIPTO' }, { id: 5, NOMBRE: 'RESP INS NO AG. RET' }, { id: 6, NOMBRE: 'RESP INSC GR/CONTR' }, { id: 7, NOMBRE: 'RESP INS AG. RET' }, { id: 8, NOMBRE: 'NO CATEG' }, { id: 9, NOMBRE: 'MONOTRIBUTO' }]
    function getNombreById(id) {
        const result = ivaNros.find(item => item.id === id);
        return result ? result.NOMBRE : null;
    }
    const iva = getNombreById(denuncia?.qnx?.ados_tip_iva)
    const jurisdiccionPas = ['', 'Persona Fisica', 'Persona Juridica']
    const ingresosBrutosPas = ['', 'No Inscripto', 'Juridiccional', 'Convenio Multilateral']
    const tipoJubilacionPas = ['', 'No Inscripto', 'Industria y Comercio', 'Autonomo']
    const serSocialPas = ['', 'No Afiliado', 'Personas Fisicas', 'Personas Juridicas']
    const ivaPas = ['', 'CONSUMIDOR FINAL', 'LIBERADO', 'NO RESP/EXENTO', 'RESP NO INSCRIPTO', 'RESP INS NO AG. RET', 'RESP INSC GR/CONTR', 'RESP INS AG. RET', 'NO CATEG', 'MONOTRIBUTO']
    return (
        <Document>
            <Page style={styles.page} size={"A4"}>
                <View style={[styles.flexBetween]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>NRO STRO:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{`${denuncia?.nroStro?.toString().substring(3)}`}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>FECHA CARGA:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{formatDate(denuncia?.fechaCarga)}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>FECHA STRO:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{formatDate(denuncia?.fechaStro)}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>SELLO:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{formatDate(denuncia?.fechaDenuncia)}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop1]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>POLIZA:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{denuncia?.qnx?.ados_poliza}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>END:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{denuncia?.qnx?.ados_endoso}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>PROP:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{denuncia?.qnx?.ados_nro_cc}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo}>STROS:</Text>
                        <Text style={[styles.titulo, styles.marginText]}>{denuncia?.otrasDenuncias?.porPatente.length > 1 || denuncia?.otrasDenuncias?.porPoliza.length > 1 ? 'SI' : 'NO'}</Text>
                    </View>
                </View>
                <Text style={styles.bordeNegro}></Text>

                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>PRODUCTOR:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{`${denuncia?.productor?.pro_productor} - ${denuncia?.productor?.pro_raz_social}`}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>DOM.PAS:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_domicilio}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>LOCALIDAD:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_localidad}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CP:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_nro_cuit}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CATEGORIA IVA:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{ivaPas[denuncia?.productor?.pro_cat_iva]}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CUIT:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_nro_cuit}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>NRO JUB:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_nro_jub}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>TIPO JUBILACION:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{tipoJubilacionPas[denuncia?.productor?.pro_jubilacion]}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>ING BRUTOS:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{ingresosBrutosPas[denuncia?.productor?.pro_ing_brutos]}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>NRO IB:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.productor?.pro_nro_ingbto}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>JURISDICCION PAS:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{jurisdiccionPas[denuncia?.productor?.pro_cod_jur]}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>SER. SOCIAL:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{serSocialPas[denuncia?.productor?.pro_ser_social]}</Text>
                    </View>
                </View>
                <Text style={styles.bordeNegro}></Text>

                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>ASEGURADO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_nom_aseg}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CUIT:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_cuit}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>DOMICILIO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_dir_aseg}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CP:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_cdp_aseg}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>LOCALIDAD:</Text>
                        {denuncia?.qnx !== undefined ? <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_loc_aseg}</Text> : null}
                    </View>
                </View>
                <Text style={styles.bordeNegro}></Text>

                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>PATENTE:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_patente}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>AÑO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_modelo}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>ITEM:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.item[0]?.item_desc}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>VEHICULO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_vehiculo}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>USO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.uso[0]?.uso_desc}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>MOTOR:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_motor}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>CHASIS:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_chasis}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>SUMA ASEG:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_sum_aseg}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>TIPO STRO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.catStro}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>COD RIESGO:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{'-----'}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>VALOR GNC:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{`${denuncia?.qnx?.ados_eq_gas}`}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>SICOBE:</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>IVA:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{`${denuncia?.qnx?.ados_tip_iva} - ${iva}`}</Text>
                    </View>
                </View>
                <Text style={styles.bordeNegro}></Text>

                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>POLIZA:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia?.qnx?.ados_poliza}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>INI.VIG:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{formatDate(denuncia?.qnx?.ados_fech_ini)}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>FECHA EMISION:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{formatDate(denuncia?.qnx?.ados_fech_emi)}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>FIN.VIG:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{formatDate(denuncia.qnx.ados_fech_fin)}</Text>
                    </View>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>FECHA EMI.PROP:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{formatDate(denuncia.qnx.ados_fech_prod)}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>COBERTURA:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia.qnx.ados_cobert}</Text>
                    </View>
                </View>
                <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}>
                        <Text style={styles.titulo2}>PLAN:</Text>
                        <Text style={[styles.titulo2, styles.marginText]}>{denuncia.qnx.ados_plan > 70 ? `${denuncia.qnx.ados_plan} - Debito Automatico` : denuncia.qnx.ados_plan}</Text>
                    </View>
                </View>
                <Text style={styles.bordeNegro}></Text>
                {denuncia.endosos.length > 1 ? <Text style={styles.titulo}>ENDOSOS</Text> : <Text style={styles.titulo}>NO EXISTEN ENDOSOS</Text>}
                {denuncia.endosos.length > 1 ? <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}><Text style={styles.titulo2}>PZA / PROP</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>FEC. INI</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>FEC. FIN</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>CAUSA</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>USUARIO</Text></View>
                </View> : null}
                {denuncia.endosos.length > 1 ? denuncia.endosos.map(d =>
                    <View style={[styles.flexBetween, styles.marginTop]}>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{d.ados_poliza}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(d.ados_fech_ini)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(d.ados_fech_fin)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{d.ados_cod_end}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{d.ados_w_user}</Text>
                        </View>
                    </View>
                ) : null}
                <Text style={styles.bordeNegro}></Text>
                {denuncia.otrasDenuncias.porPatente.length > 1 || denuncia.otrasDenuncias.porPoliza.length > 1 ? <Text style={styles.titulo}>STROS</Text> : <Text style={styles.titulo}>NO EXISTEN OTROS STROS</Text>}
                {denuncia.otrasDenuncias.porPatente.length > 1 || denuncia.otrasDenuncias.porPoliza.length > 1 ? <View style={[styles.flexBetween, styles.marginTop]}>
                    <View style={styles.flex}><Text style={styles.titulo2}>NRO STRO</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>PATENTE</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>POLIZA</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>FEC STRO</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>INI VIG</Text></View>
                    <View style={styles.flex}><Text style={styles.titulo2}>FIN VIG</Text></View>
                </View> : null}
                {denuncia.otrasDenuncias.porPoliza.filter(d => d.id !== denuncia.id).map(a =>
                    <View style={[styles.flexBetween, styles.marginTop]}>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a.nroStro ? a?.nroStro?.toString().substring(3) : '---'}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a.dom}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a.pza}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.fechaStro)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.qnx.ados_fech_ini)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.qnx.ados_fech_fin)}</Text>
                        </View>
                    </View>
                )}
                {denuncia.otrasDenuncias.porPatente.filter(d => d.id !== denuncia.id).map(a =>
                    <View style={[styles.flexBetween, styles.marginTop]}>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a?.nroStro?.toString().substring(3)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a.dom}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{a.pza}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.fechaStro)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.qnx?.ados_fech_ini)}</Text>
                        </View>
                        <View style={styles.flex}>
                            <Text style={styles.titulo2}>{formatDate(a.qnx?.ados_fech_fin)}</Text>
                        </View>
                    </View>
                )}
                <View style={styles.pageNumber}><Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} /></View> : ''
            </Page>
        </Document>
    );
}

export default FrentePdf;