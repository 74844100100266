import './carga.css'
export default function MensajesCronologiaCarga({ mensaje }) {
    return (
        <div className='mensajeCronologiaMensaje row w-100 mt-3 ms-2'>
            <div className="col-3 contenedorPrimeroCronologia">
                <h6>{`${mensaje.fecha} | ${mensaje.hora}`}</h6>
                <h6>{`Autor: ${mensaje.autor}`}</h6>
                <div className='d-flex'>
                    <h6 className={mensaje.motivo === 'STRO' ? 'motivoStro center text-white' : (mensaje.motivo === 'AMP' ? 'motivoAmp center text-white' : (mensaje.motivo === 'COTI' ? 'motivoCoti center text-white' : (mensaje.motivo === 'CIA' ? 'motivoCia center text-white' : (mensaje.motivo === 'FIN' ? 'motivoFin center text-white' : ''))))}>{mensaje.motivo}</h6>
                    <h6 className={mensaje.jerarquia === 'CRG' ? 'jerarquiaCrg center text-white' : (mensaje.jerarquia === 'DEN' ? 'jerarquiaDen center text-white' : '')}>{mensaje.jerarquia}</h6>
                </div>
            </div>
            <div className="col-8">
                <div className='d-flex'>
                    <h6>{`Desc: ${mensaje.desc}`}</h6>
                </div>
                <div className='mensajeCronologiaMensaje1'>
                    <h6>{`Obs usuario: ${mensaje.obs}`}</h6>
                </div>
            </div>
        </div>
    );
}
