import './carga.css'
import { useCallback, useState } from 'react';
import axios from 'axios';
export default function DenunciaAdminSeccion({ denuncia}) {
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    const logo = { height: '38px', width: '38px' }
    const [link1, setLink1] = useState({ link: denuncia?.link });
    const linkUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://apifotos.agsseguros.online/api/denuncia/linkSharepoint`,
            {
                id: denuncia.id,
                link: link1.link,
            }
        ).then((response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [denuncia, link1]);
    const handleLinkClick = (event) => {
        if (!denuncia.link.startsWith('http')) {
            event.preventDefault();
            window.open(`https://${denuncia.link}`, '_blank');
        }
    };
    return (
        <section className=''>
            <div className="d-flex justify-content-between align-items-center pt-2 pe-4">
                <h2 className="tituloStroTabla2 m-0 p-0">Denuncia Administrativa</h2>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Fecha Stro</label>
                <div className="inputStroVigencia d-flex align-items-center text-danger">
                    <p className="m-0 textoCarga">{formatDate(denuncia.fechaStro)}</p>
                </div>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Poliza</label>
                <div className={Number(denuncia?.pza) === Number(denuncia?.qnx?.ados_poliza) ? "inputStroVigencia d-flex align-items-center" : 'inputStroVigencia d-flex align-items-center border-danger'}>
                    <p className="m-0 textoCarga">{denuncia?.pza}</p>
                </div>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Nombre Aseg</label>
                <div className={denuncia?.nombreAseg === denuncia?.qnx?.ados_nom_aseg ? "inputStroVigencia d-flex align-items-center" : 'inputStroVigencia d-flex align-items-center border-danger'}>
                    <p className="m-0 textoCarga">{denuncia?.nombreAseg}</p>
                </div>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Patente</label>
                <div className={denuncia?.dom === denuncia?.qnx?.ados_patente ? "inputStroVigencia d-flex align-items-center" : 'inputStroVigencia d-flex align-items-center border-danger'}>
                    <p className="m-0 textoCarga">{denuncia?.dom}</p>
                </div>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Marca</label>
                <div className="inputStroVigencia d-flex align-items-center text-danger">
                    <p className="m-0 textoCarga">{denuncia?.marcaAseg}</p>
                </div>
            </div>
            <div className="d-flex align-items-center infoStroElemento">
                <label className="labelStro">Modelo</label>
                <div className="inputStroVigencia d-flex align-items-center text-danger">
                    <p className="m-0 textoCarga">{denuncia?.modeloAseg}</p>
                </div>
            </div>
            <div className="d-flex align-items-start infoStroElemento">
                <label className="labelStro pt-2">Relato</label>
                <div className="inputStroVigenciaTextarea2 d-flex align-items-start pt-2">
                    <p className="m-0 textoCarga">{denuncia?.detallesStro}</p>
                </div>
            </div>
            <div className="d-flex align-items-start infoStroElemento">
                <label className="labelStro pt-2">Daños</label>
                <div className="inputStroVigenciaTextarea d-flex align-items-start pt-2">
                    <p className="m-0 textoCarga">{denuncia?.detallesDaniosAseg}</p>
                </div>
            </div>
        </section>
    );
}

