import axios from "axios";
import { useCallback, useState } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
export default function Login() {
    const [error, setError] = useState(null);
    const [signUpData, setSignUpData] = useState({ usuario: '', password: '' })
    const [cookies, setCookie] = useCookies(['user'], ['text'], ['ticket'], ['asignado']);
    const navigate = useNavigate()
    const [userFalse, setUserFalse] = useState(false)
    const userSignUpSubmit = useCallback(async (e) => {
        e.preventDefault()
        await axios.post("https://apifotos.agsseguros.online/api/login/login", { usuario: signUpData.usuario }).then((response) => {
            if (response.data === 'Invalid OAuth') {
                setUserFalse(true)
            } else {
                setCookie('exp', JSON.stringify([]), { path: '/' })
                setCookie('user', response.data, { path: '/' })
                setCookie('asignado', JSON.stringify([]), { path: '/' })
                navigate('/')
            }
        })
    }, [signUpData, navigate, setCookie])
    return (
        <main className=" center">
            <form onSubmit={userSignUpSubmit}>
                <div>
                    <input type="text" required value={signUpData.usuario} className="form-control" placeholder="USUARIO"
                        onChange={(e) =>
                            setSignUpData((prevState) => ({
                                ...prevState,
                                usuario: e.target.value,
                            }))} />
                </div>
                <div className={userFalse === false ? 'd-none' : 'userFalseLogin'}><p className="text-danger">Usuario y/o Contraseña incorrectos</p></div>
                <div className="submitLogin center mt-5"><button type="submit" className="btn btn-primary">Iniciar Sesion</button></div>
            </form>
        </main>
    );
}

