import { useEffect, useState } from "react";
import "../../pages/cargaTabla.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { checkContext } from "../../context/Checka";
export default function ComponenteTablaCarga({ denuncia }) {
    const link = { textDecoration: "none" };
    const { checka, setChecka } = useContext(checkContext);
    const [check, setChecked] = useState(false);
    function handleChange(e) {
        setChecked(e.target.checked);
        if (e.target.checked) {
            if (!checka.includes(denuncia.id)) {
                setChecka((prevChecka) => [...prevChecka, denuncia.id]);
            }
        } else {
            if (checka.includes(denuncia.id)) {
                setChecka((prevChecka) =>
                    prevChecka.filter((item) => item !== denuncia.id)
                );
            }
        }
    }
    function formatDate(dateString) {
        const [year, month, day] = dateString.split("-");
        return `${day}-${month}-${year}`;
    }
    const handleClick = (event, id) => {
        event.preventDefault();
        if (event.ctrlKey || event.metaKey) {
            window.open(`/#/${id}`, "_blank");
        } else {
            event.preventDefault();
            window.location.href = event.currentTarget.href;
        }
    };
    const estados = [
        { value: 1, label: "CARGADO", tipoStro: "GENERAL" },
        { value: 2, label: "CERRADO", tipoStro: "GENERAL" },

        { value: 101, label: "RR", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 102, label: "CTZ", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 103, label: "ASIG", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 110, label: "ACIA", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 104, label: "RCIA", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 105, label: "IN/REC", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 106, label: "TER", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 107, label: "CER", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 108, label: "ARCH", tipoStro: "ACCIDENTE DE TRANSITO" },
        { value: 109, label: "LEGALES", tipoStro: "ACCIDENTE DE TRANSITO" },

        { value: 201, label: "EN PROCESO", tipoStro: "ROBO TOTAL" },
        {
            value: 202,
            label: "ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "ROBO TOTAL",
        },
        {
            value: 203,
            label: "ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "ROBO TOTAL",
        },
        { value: 204, label: "ESPERA BAJA", tipoStro: "ROBO TOTAL" },
        { value: 205, label: "BAJA A GESTOR", tipoStro: "ROBO TOTAL" },
        { value: 206, label: "BAJA DEVUELTA POR GESTOR", tipoStro: "ROBO TOTAL" },
        { value: 207, label: "ESTADO BAJA", tipoStro: "ROBO TOTAL" },
        { value: 208, label: "EN PROCESO JJ", tipoStro: "ROBO TOTAL" },
        { value: 209, label: "EN LIQUIDACIÓN", tipoStro: "ROBO TOTAL" },
        { value: 210, label: "EN ESPERA ACEPTACIÓN", tipoStro: "ROBO TOTAL" },
        {
            value: 211,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "ROBO TOTAL",
        },
        { value: 212, label: "TERMINADO RECHAZADO", tipoStro: "ROBO TOTAL" },
        { value: 213, label: "LEGALES", tipoStro: "ROBO TOTAL" },

        {
            value: 301,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "ROBO PARCIAL",
        },
        { value: 302, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "ROBO PARCIAL" },
        { value: 303, label: "EN PROCESO", tipoStro: "ROBO PARCIAL" },
        { value: 310, label: "ARCHIVO", tipoStro: "ROBO PARCIAL" },
        {
            value: 304,
            label: "ARCHIVO ESPERA ACEPTACIÓN",
            tipoStro: "ROBO PARCIAL",
        },
        {
            value: 305,
            label: "ARCHIVO FACTURA PROVEEDOR",
            tipoStro: "ROBO PARCIAL",
        },
        {
            value: 306,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "ROBO PARCIAL",
        },
        { value: 307, label: "TERMINADO A TESORERÍA", tipoStro: "ROBO PARCIAL" },
        { value: 308, label: "TERMINADO RECHAZADO", tipoStro: "ROBO PARCIAL" },
        { value: 309, label: "LEGALES", tipoStro: "ROBO PARCIAL" },

        {
            value: 401,
            label: "EN ESPERA INFORME INSPECCIÓN",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 402,
            label: "EN ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 403,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 404,
            label: "ESPERA FACTURA PROVEEDOR",
            tipoStro: "ROBO PARCIAL INSP",
        },
        { value: 405, label: "EN PROCESO", tipoStro: "ROBO PARCIAL INSP" },
        { value: 412, label: "ARCHIVO", tipoStro: "ROBO PARCIAL INSP" },
        {
            value: 406,
            label: "ARCHIVO ESPERA ACEPTACIÓN",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 407,
            label: "ARCHIVO FACTURA PROVEEDOR",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 408,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "ROBO PARCIAL INSP",
        },
        {
            value: 409,
            label: "TERMINADO A TESORERÍA",
            tipoStro: "ROBO PARCIAL INSP",
        },
        { value: 410, label: "TERMINADO RECHAZADO", tipoStro: "ROBO PARCIAL INSP" },
        { value: 411, label: "LEGALES", tipoStro: "ROBO PARCIAL INSP" },

        {
            value: 501,
            label: "EN ESPERA INFORME INSPECCIÓN",
            tipoStro: "INCENDIO TOTAL",
        },
        {
            value: 502,
            label: "EN ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "INCENDIO TOTAL",
        },
        {
            value: 503,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "INCENDIO TOTAL",
        },
        {
            value: 504,
            label: "ESPERA FACTURA PROVEEDOR",
            tipoStro: "INCENDIO TOTAL",
        },
        { value: 505, label: "EN PROCESO", tipoStro: "INCENDIO TOTAL" },
        { value: 512, label: "ARCHIVO", tipoStro: "INCENDIO TOTAL" },
        {
            value: 506,
            label: "ARCHIVO ESPERA ACEPTACIÓN",
            tipoStro: "INCENDIO TOTAL",
        },
        {
            value: 507,
            label: "ARCHIVO FACTURA PROVEEDOR",
            tipoStro: "INCENDIO TOTAL",
        },
        {
            value: 508,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "INCENDIO TOTAL",
        },
        { value: 509, label: "TERMINADO A TESORERÍA", tipoStro: "INCENDIO TOTAL" },
        { value: 510, label: "TERMINADO RECHAZADO", tipoStro: "INCENDIO TOTAL" },
        { value: 511, label: "LEGALES", tipoStro: "INCENDIO TOTAL" },

        {
            value: 601,
            label: "EN ESPERA INFORME INSPECCIÓN",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 602,
            label: "EN ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 603,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 604,
            label: "ESPERA FACTURA PROVEEDOR",
            tipoStro: "INCENDIO PARCIAL",
        },
        { value: 605, label: "EN PROCESO", tipoStro: "INCENDIO PARCIAL" },
        { value: 612, label: "ARCHIVO", tipoStro: "INCENDIO PARCIAL" },
        {
            value: 606,
            label: "ARCHIVO ESPERA ACEPTACIÓN",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 607,
            label: "ARCHIVO FACTURA PROVEEDOR",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 608,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "INCENDIO PARCIAL",
        },
        {
            value: 609,
            label: "TERMINADO A TESORERÍA",
            tipoStro: "INCENDIO PARCIAL",
        },
        { value: 610, label: "TERMINADO RECHAZADO", tipoStro: "INCENDIO PARCIAL" },
        { value: 611, label: "LEGALES", tipoStro: "INCENDIO PARCIAL" },

        {
            value: 701,
            label: "EN ESPERA INFORME INSPECCIÓN",
            tipoStro: "DAÑO TOTAL",
        },
        {
            value: 702,
            label: "EN ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "DAÑO TOTAL",
        },
        {
            value: 703,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "DAÑO TOTAL",
        },
        { value: 704, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "DAÑO TOTAL" },
        { value: 705, label: "EN PROCESO", tipoStro: "DAÑO TOTAL" },
        { value: 712, label: "ARCHIVO", tipoStro: "DAÑO TOTAL" },
        { value: 706, label: "ARCHIVO ESPERA ACEPTACIÓN", tipoStro: "DAÑO TOTAL" },
        { value: 707, label: "ARCHIVO FACTURA PROVEEDOR", tipoStro: "DAÑO TOTAL" },
        {
            value: 708,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "DAÑO TOTAL",
        },
        { value: 709, label: "TERMINADO A TESORERÍA", tipoStro: "DAÑO TOTAL" },
        { value: 710, label: "TERMINADO RECHAZADO", tipoStro: "DAÑO TOTAL" },
        { value: 711, label: "LEGALES", tipoStro: "DAÑO TOTAL" },

        {
            value: 801,
            label: "EN ESPERA INFORME INSPECCIÓN",
            tipoStro: "DAÑO PARCIAL",
        },
        {
            value: 802,
            label: "EN ESPERA INFORME INVESTIGACIÓN",
            tipoStro: "DAÑO PARCIAL",
        },
        {
            value: 803,
            label: "EN ESPERA DOCUMENTACIÓN PRODUCTOR",
            tipoStro: "DAÑO PARCIAL",
        },
        { value: 804, label: "ESPERA FACTURA PROVEEDOR", tipoStro: "DAÑO PARCIAL" },
        { value: 805, label: "EN PROCESO", tipoStro: "DAÑO PARCIAL" },
        { value: 812, label: "ARCHIVO", tipoStro: "DAÑO PARCIAL" },
        {
            value: 806,
            label: "ARCHIVO ESPERA ACEPTACIÓN",
            tipoStro: "DAÑO PARCIAL",
        },
        {
            value: 807,
            label: "ARCHIVO FACTURA PROVEEDOR",
            tipoStro: "DAÑO PARCIAL",
        },
        {
            value: 808,
            label: "TERMINADO DESCUENTO A MORÓN",
            tipoStro: "DAÑO PARCIAL",
        },
        { value: 809, label: "TERMINADO A TESORERÍA", tipoStro: "DAÑO PARCIAL" },
        { value: 810, label: "TERMINADO RECHAZADO", tipoStro: "DAÑO PARCIAL" },
        { value: 811, label: "LEGALES", tipoStro: "DAÑO PARCIAL" },
    ];
    const optionsPrioridad = [{ label: '', value: 0 }, { label: 'BAJA', value: 1 }, { label: 'MEDIA', value: 2 }, { label: 'ALTA', value: 3 }]
    const prioridadSeleccionado = optionsPrioridad.find(p => p.value === Number(denuncia.prioridad));
    return (
        <tr className="">
            <th scope="col" className="ps-0 pe-0">
                <div className="d-flex">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        onChange={handleChange}
                        checked={checka.includes(denuncia.id) ? true : false}
                    />
                    <h4 className="m-0 p-0 ps-1 w-100 tablaCargaBodyText1 text-center">
                        {denuncia?.nroStro?.toString().substring(3)}
                    </h4>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 className="m-0 p-0 w-100 tablaCargaBodyText1">
                    {denuncia?.entryId}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 className="m-0 p-0 w-100 tablaCargaBodyText1">{denuncia?.pza}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <div class="tablaCargaBodyText2">{denuncia?.dom}</div>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 class="tablaCargaBodyText2">
                    {formatDate(denuncia?.fechaDenuncia)}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 class="tablaCargaBodyText2">{formatDate(denuncia?.fechaStro)}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 class="tablaCargaBodyText2">
                    {denuncia?.recepcionRecChoques
                        ? formatDate(denuncia?.recepcionRecChoques)
                        : null}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 class="tablaCargaBodyText2">
                    {denuncia?.fechaVencimiento
                        ? formatDate(denuncia?.fechaVencimiento)
                        : null}
                </h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <h4 class="tablaCargaBodyText2">{prioridadSeleccionado.label}</h4>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <div class="tablaCargaBodyText2">
                    <div className="d-flex align-items-center">
                        <div
                            className={
                                denuncia.empleadoAsignado ? "circleVioleta me-1 center" : ""
                            }
                        ></div>
                        {denuncia.empleadoAsignado}
                    </div>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <div class="tablaCargaBodyText2">
                    <div className="d-flex align-items-center">
                        <div
                            className={
                                denuncia.estado === 2
                                    ? "circleRojo me-1"
                                    : denuncia.estado >= 1
                                        ? "circleVerde me-1"
                                        : "circleGris me-1"
                            }
                        ></div>
                        {denuncia.estado > 0
                            ? estados.find((e) => e.value === Number(denuncia.estado))
                                ?.label || "LABEL NO ENCONTRADO"
                            : "SIN LEER"}
                    </div>
                </div>
            </th>
            <th scope="col" className="ps-0 pe-0">
                <Link
                    to={`/${denuncia.id}`}
                    onClick={(event) => handleClick(event, denuncia.id)}
                    className="tablaCargaBodyText2 text-primary"
                >
                    INGRESAR
                </Link>
            </th>
        </tr>
    );
}
