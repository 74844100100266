import { useParams } from "react-router-dom";
import "./carga.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
export default function TablaOtrasDenuncias({ }) {
    const id = useParams().id;
    const [denuncia, setDenuncia] = useState([])
    const [cargando, setCargando] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDenuncia = await axios.get(`https://apifotos.agsseguros.online/api/siniestros/${id}`);
                setDenuncia(resDenuncia.data);
                setCargando(true)
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [id]);
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }
    return (
        <>
            {cargando ? <section>
                <table class="mt-3 table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Nro Stro</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Asegurado</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Patente</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Pol/Pres</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Fecha Stro</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">
                                    Inicio Vigencia
                                </h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Fin Vigencia</h6>
                            </th>
                            <th scope="col">
                                <h6 className="textoHeaderTablaOtrasDenuncias">Tipo Stro</h6>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="">
                            <td className="textoBodyTablaOtrasDenuncias bg-success text-white">{denuncia?.nroStro !== null ? denuncia?.nroStro?.toString().substring(3) : ''}</td>
                            <td className="textoBodyTablaOtrasDenuncias2 bg-success text-white">{denuncia?.nombreConductorAseg}</td>
                            <td className="textoBodyTablaOtrasDenuncias2 bg-success text-white">{denuncia?.dom}</td>
                            <td className="textoBodyTablaOtrasDenuncias2 bg-success text-white">{denuncia?.pza}</td>
                            <td className="textoBodyTablaOtrasDenuncias bg-success text-white">{formatDate(denuncia?.fechaStro)}</td>
                            <td className="textoBodyTablaOtrasDenuncias bg-success text-white">{formatDate(denuncia?.qnx?.ados_fech_ini)}</td>
                            <td className="textoBodyTablaOtrasDenuncias bg-success text-white">{formatDate(denuncia?.qnx?.ados_fech_fin)}</td>
                            <td className="textoBodyTablaOtrasDenuncias bg-success text-white">{denuncia?.catStro}</td>
                        </tr>
                        <div className="bordeNegro mt-3"></div>
                        <h5 className="mt-3">{denuncia?.otrasDenuncias?.porPoliza?.length > 1 ? 'Por Poliza' : 'No existen Otros con la misma Poliza'}</h5>
                        {denuncia?.otrasDenuncias?.porPoliza?.filter(p => p.id !== denuncia.id).map(p =>
                            <tr className="">
                                <td className="textoBodyTablaOtrasDenuncias">{p.nroStro !== null ? p.nroStro.toString().substring(3) : null}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.nombreConductorAseg}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.dom}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.pza}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{formatDate(p.fechaStro)}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{formatDate(p.qnx?.ados_fech_ini)}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{formatDate(p.qnx?.ados_fech_fin)}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{p.catStro}</td>
                            </tr>
                        )}
                        <div className="bordeNegro mt-3"></div>
                        <h5 className="mt-3">{denuncia?.otrasDenuncias?.porPatente?.length > 1 ? 'Por Patente' : 'No existen Otros con la misma Patente'}</h5>
                        {denuncia?.otrasDenuncias?.porPatente?.filter(p => p.id !== denuncia.id).map(p =>
                            <tr className="">
                                <td className="textoBodyTablaOtrasDenuncias">{p.nroStro ? p.nroStro.toString().substring(3) : null}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.nombreConductorAseg}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.dom}</td>
                                <td className="textoBodyTablaOtrasDenuncias2">{p.pza}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{formatDate(p.fechaStro)}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{p.qnx?.ados_fech_ini ? formatDate(p.qnx?.ados_fech_ini) : null}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{p.qnx?.ados_fech_fin ? formatDate(p.qnx?.ados_fech_fin) : null}</td>
                                <td className="textoBodyTablaOtrasDenuncias">{p.catStro}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </section> : <div className="center"><div class="spinner-border text-success center" role="status"><span class="visually-hidden center">Loading...</span></div></div>}
        </>
    );
}